<template>
	<ValidationObserver v-slot="{ invalid }" slim>
		<SortableTable
			:headers="headers"
			:items="glossaryStore.keywords"
			expand-all
			item-unique-key="path"
			:search.sync="search"
			show-pagination
			show-title
			title="Glossary terms"
			class="rounded-md bg-white"
		>
			<template #toolbar>
				<!--
							This will loop over existing hardcoded keywords & save them all to api endpoint
							Get rid of it once everything's updated
							-->
				<BaseButton color="error" dark @click="addAllKeywords">
					Add All keywords
				</BaseButton>

				<BaseButton color="primary" dark @click="addKeyword"> New Keyword </BaseButton>

				<BaseDialog :value.sync="showDialog" :loading="loading" max-width="800" dense>
					<section class="space-y-5 rounded-lg bg-white p-4">
						<h3 class="text-bold mb-4 text-center text-3xl">Edit Keyword</h3>
						<div class="flex gap-2">
							<div class="basis-1/3 space-y-5">
								<ValidationProvider
									v-slot="{ errors }"
									name="Title"
									mode="eager"
									rules="required"
								>
									<TextFieldInput
										:value.sync="keyword.title"
										:disabled="mode === 'deleting'"
										label="Title"
										:error-messages="errors"
									/>
								</ValidationProvider>

								<TextFieldInput
									:value.sync="keyword.path"
									:disabled="mode === 'deleting'"
									label="Path"
								/>
							</div>

							<BaseTextArea
								class="basis-2/3"
								:value.sync="keyword.description"
								:rows="4"
								:disabled="mode === 'deleting'"
								label="Description"
							/>
						</div>

						<GlossaryContentTable
							:items.sync="keyword.examples"
							:disabled="mode === 'deleting'"
							item-name="Examples"
						/>

						<GlossaryContentTable
							:items.sync="keyword.notes"
							:disabled="mode === 'deleting'"
							item-name="Notes"
						/>

						<footer class="mt-4 flex items-center justify-end gap-2">
							<BaseButton color="gray" text @click="cancelChanges">
								Cancel
							</BaseButton>
							<BaseButton
								:color="mode === 'deleting' ? 'error' : 'orange'"
								:disabled="mode !== 'deleting' && invalid"
								@click="saveChanges"
							>
								{{ mode === 'deleting' ? 'Delete' : 'Save' }}
							</BaseButton>
						</footer>
					</section>
				</BaseDialog>
			</template>

			<template #item_expanded="keywordData">
				<h3 class="p-2 text-center text-3xl font-medium">Preview</h3>
				<div class="mt-0 p-2">
					<KeywordContent :keyword="keywordData.path" :preview="keywordData" />
				</div>
			</template>

			<template #item.title="{ title }">
				<span class="font-medium text-gray-900 sm:pl-6">
					{{ title }}
				</span>
			</template>
			<template #item.description="{ description }">
				<div>{{ description.substring(0, 60) }}...</div>
				<!-- <div class="whitespace-normal" v-html="description" /> -->
			</template>
			<template #item.examples="{ examples }">
				{{ examples?.length ?? 0 }}
			</template>
			<template #item.notes="{ notes }">
				{{ notes?.length ?? 0 }}
			</template>

			<template #item.actions="item">
				<div class="flex items-center justify-center gap-x-2">
					<InfoLink class="mr-1" icon-size="lg" :keyword="item.path" :show-text="false" />

					<button type="button" @click="updateKeyword({ item, mode: 'editing' })">
						<FAIcon class="mr-2 text-lg text-orange" icon="pencil" />
					</button>
					<button type="button" @click="updateKeyword({ item, mode: 'adding' })">
						<FAIcon class="mr-2 text-lg text-green-600" icon="copy" />
					</button>
					<button type="button" @click="updateKeyword({ item, mode: 'deleting' })">
						<FAIcon class="text-lg text-red" icon="trash" />
					</button>
				</div>
			</template>
		</SortableTable>
	</ValidationObserver>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue';
import InfoLink from '@/components/glossary/InfoLink';
import KeywordContent from '@/components/glossary/KeywordContent';
import keywordList from '@/components/glossary/keywordList';

import GlossaryContentTable from '@/components/MirusControls/GlossaryContentTable';

import { useGlossaryStore } from '@/stores/glossary';

import TextFieldInput from '@/components/ui/TextFieldInput';
import BaseButton from '@/components/ui/BaseButton';
import BaseTextArea from '@/components/ui/BaseTextArea';
import SortableTable from '@/components/ui/SortableTable';
import BaseDialog from '@/components/ui/BaseDialog';

const headers = [
	{ sortable: true, text: 'Title', value: 'title', filterable: true },
	{ sortable: false, text: 'Description', value: 'description', filterable: true },
	{ sortable: false, text: 'Examples', value: 'examples' },
	{ sortable: false, text: 'Notes', value: 'notes' },
	{ sortable: false, text: 'Actions', value: 'actions' },
];

const glossaryStore = useGlossaryStore();

const loading = ref(false);
const search = ref(``);
const showDialog = ref(false);

const mode = ref(null);
const id = ref(null);

const keyword = reactive({
	title: ``,
	description: ``,
	path: ``,
	visible: true,
	examples: [],
	notes: [],
});

function resetKeyword() {
	keyword.title = ``;
	keyword.description = ``;
	keyword.path = ``;
	keyword.visible = true;
	keyword.examples = [];
	keyword.notes = [];
}

function updateKeyword({ item, mode: newMode }) {
	const { title, description, examples, notes, path } = item;
	keyword.title = title;
	keyword.description = description;
	keyword.path = path;
	keyword.examples = examples ? examples : [];
	keyword.notes = notes ? notes : [];

	mode.value = newMode;
	id.value = item.id;
	showDialog.value = true;
}

function addKeyword() {
	resetKeyword();
	showDialog.value = true;
}
function cancelChanges() {
	mode.value = undefined;
	showDialog.value = false;
	resetKeyword();
}
async function saveChanges() {
	loading.value = true;
	let action;
	switch (mode.value) {
		case 'editing':
			action = `updateKeyword`;
			break;
		case 'deleting':
			action = `deleteKeyword`;
			break;
		default:
			action = `saveKeyword`;
	}
	await glossaryStore[action]({
		json: { ...keyword },
		id: id.value,
	});

	loading.value = false;
	showDialog.value = false;
	mode.value = null;
	id.value = undefined;
	resetKeyword();
}
async function addAllKeywords() {
	Object.entries(keywordList).forEach(async ([key, value]) => {
		updateKeyword({
			item: {
				...value,
				path: key,
				examples: value.examples || [],
				notes: value.notes || [],
			},
		});
		await saveChanges();
	});
}
onMounted(async () => {
	loading.value = true;
	await glossaryStore.getKeywordList();
	loading.value = false;
});
</script>
