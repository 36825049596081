<template>
	<ValidationObserver v-slot="{ invalid, handleSubmit, pristine }" slim>
		<form @submit.prevent="handleSubmit(onSave)">
			<header class="flex items-center justify-between gap-2 rounded-lg bg-gray-100 p-2">
				<BaseButton color="gray" text @click="onCancel">
					<FAIcon icon="arrow-left" size="lg" class="mr-1" />
					Back
				</BaseButton>

				<h3 v-if="card" class="text-xl font-semibold leading-none">
					<FAIcon icon="pencil" size="xs" /> Editing {{ card.card_type }} ...{{
						card.last_4
					}}
				</h3>
				<h3 v-else class="text-xl leading-none">New Payment Method</h3>

				<div>
					<BaseButton color="gray" class="mx-1" :disabled="saving" @click="onCancel">
						Cancel
					</BaseButton>
					<BaseButton color="primary" type="submit" :disabled="invalid || pristine">
						Save
					</BaseButton>
				</div>
			</header>
			<ProgressBar v-if="saving" class="w-full" />
			<BaseAlert v-if="error" tile type="error">
				{{ error }}
			</BaseAlert>

			<h4 class="mt-5 text-2xl font-bold text-gray-600">Card Info</h4>

			<div class="mt-3 grid grid-cols-3 gap-5">
				<ValidationProvider
					v-slot="{ errors }"
					name="Card Number"
					:rules="{ credit_card: true, required: newCard }"
					class="col-span-3"
				>
					<TextFieldInput
						:value.sync="fields.number"
						label="Credit Card Number"
						:placeholder="cardNumberPlaceholder"
					>
						<template v-if="errors" #message>
							<span>{{ errors[0] }}</span>
						</template>
					</TextFieldInput>
				</ValidationProvider>

				<ValidationProvider
					v-slot="{ errors }"
					name="Expiration Month"
					:rules="{
						required: true,
						card_expiration_month: { year: fields.expiration_year },
					}"
				>
					<BaseSelect
						id="expiration-month"
						:value.sync="fields.expiration_month"
						label="Expiration Month"
						:items="months"
						show-label
						outlined
					>
						<template v-if="errors[0]" #message>
							<span>{{ errors[0] }}</span>
						</template>
					</BaseSelect>
				</ValidationProvider>

				<ValidationProvider
					v-slot="{ errors }"
					name="Expiration Year"
					:rules="{
						required: true,
						card_expiration_year: { month: fields.expiration_month },
					}"
				>
					<TextFieldInput
						:value.sync="fields.expiration_year"
						label="Expiration Year"
						type="number"
						:min="0"
						:max="9999"
						placeholder="2101"
					>
						<template #hint> Example: 2101 </template>

						<template v-if="errors" #message>
							<span>{{ errors[0] }}</span>
						</template>
					</TextFieldInput>
				</ValidationProvider>

				<ValidationProvider
					v-slot="{ errors }"
					name="Security Code"
					:rules="{
						between: { min: 0, max: 9999 },
						required: newCard,
						card_security_code: { number: fields.number },
					}"
				>
					<TextFieldInput
						:value.sync="fields.cvv"
						label="Security Code"
						type="number"
						:min="0"
						:max="9999"
						placeholder="000"
					>
						<template #hint> Example: 567 </template>

						<template v-if="errors" #message>
							{{ errors[0] }}
						</template>
					</TextFieldInput>
				</ValidationProvider>

				<ValidationProvider
					v-slot="{ errors }"
					name="Cardholder Name"
					:rules="{ required: true }"
					class="col-span-3"
				>
					<TextFieldInput
						:value.sync="fields.cardholder_name"
						label="Cardholder Name (as it appears on your card)"
						placeholder="Firstname McLastname"
					>
						<template v-if="errors" #message>
							{{ errors[0] }}
						</template>
					</TextFieldInput>
				</ValidationProvider>
			</div>

			<div class="mt-8 flex items-center gap-2">
				<h4 class="text-2xl font-bold text-gray-600">Billing Address</h4>
				<BaseButton @click="onFill">
					<template #prepend>
						<FAIcon icon="wand-magic-sparkles" />
					</template>
					Auto Fill Using Our Records
				</BaseButton>
			</div>

			<div class="mt-5 grid grid-cols-6 gap-5">
				<ValidationProvider
					v-slot="{ errors }"
					name="First Name"
					:rules="{ required: true }"
					class="col-span-3"
				>
					<TextFieldInput
						:value.sync="fields.first_name"
						label="First Name"
						placeholder="First Name"
					>
						<template v-if="errors" #message>
							{{ errors[0] }}
						</template>
					</TextFieldInput>
				</ValidationProvider>

				<ValidationProvider
					v-slot="{ errors }"
					name="Last Name"
					:rules="{ required: true }"
					class="col-span-3"
				>
					<TextFieldInput
						:value.sync="fields.last_name"
						label="Last Name"
						placeholder="Last Name"
					>
						<template v-if="errors" #message>
							{{ errors[0] }}
						</template>
					</TextFieldInput>
				</ValidationProvider>

				<ValidationProvider
					v-slot="{ errors }"
					name="Street Address"
					:rules="{ required: true }"
					class="col-span-6"
				>
					<TextFieldInput
						:value.sync="fields.street_address"
						label="Street Address"
						placeholder="Example: 123 Fake St"
					>
						<template v-if="errors" #message>
							{{ errors[0] }}
						</template>
					</TextFieldInput>
				</ValidationProvider>

				<TextFieldInput
					:value.sync="fields.extended_address"
					label="Apartment, PO box or Building ID"
					placeholder="Example: 2101"
					class="col-span-6"
				/>

				<ValidationProvider
					v-slot="{ errors }"
					name="City"
					:rules="{ required: true }"
					class="col-span-2"
				>
					<TextFieldInput
						:value.sync="fields.locality"
						label="City"
						placeholder="city"
						transparent
						outlined
					>
						<template #hint> Example: Bloomington </template>
						<template v-if="errors" #message>
							{{ errors[0] }}
						</template>
					</TextFieldInput>
				</ValidationProvider>

				<ValidationProvider
					v-slot="{ errors }"
					name="State"
					:rules="{ required: true }"
					class="col-span-2"
				>
					<BaseSelect
						id="region"
						:value.sync="fields.region"
						label="State"
						:items="states"
						show-label
						outlined
					>
						<template v-if="errors[0]" #message>
							<span>{{ errors[0] }}</span>
						</template>
					</BaseSelect>
				</ValidationProvider>

				<ValidationProvider
					v-slot="{ errors }"
					name="Postal Code"
					:rules="{ required: true }"
					class="col-span-2"
				>
					<TextFieldInput
						:value.sync="fields.postal_code"
						label="Postal Code"
						type="number"
						placeholder="61761"
					>
						<template #hint> Example: 61761 </template>
						<template v-if="errors" #message>
							{{ errors[0] }}
						</template>
					</TextFieldInput>
				</ValidationProvider>
			</div>
		</form>
	</ValidationObserver>
</template>

<script setup>
import { onMounted, computed, ref } from 'vue';
import { storeToRefs } from 'pinia';

import useAgentStore from '@/stores/agent/agent';
import usePaymentStore from '@/stores/agent/agent-payment';

import TextFieldInput from '@/components/ui/TextFieldInput';
import BaseSelect from '@/components/ui/BaseSelect';

import BaseAlert from '@/components/ui/BaseAlert.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';

import { states, months } from '@/components/payment/constants.js';

const emit = defineEmits('close');

const props = defineProps({
	card: Object,
});

const agentStore = useAgentStore();
const paymentStore = usePaymentStore();
const { agent } = storeToRefs(agentStore);

const saving = ref(false);
const error = ref(null);

const fields = ref({
	number: null,
	expiration_month: null,
	expiration_year: null,
	cvv: null,
	cardholder_name: null,
	first_name: null,
	last_name: null,
	street_address: null,
	extended_address: null,
	region: null,
	locality: null,
	postal_code: null,
});

const newCard = computed(() => !props.card);
const cardNumberPlaceholder = computed(() => props.card?.masked_number ?? 'XXXX-XXXX-XXXX-XXXX');

onMounted(() => {
	if (props.card) {
		fields.value = {
			expiration_month: props.card.expiration_month,
			expiration_year: props.card.expiration_year,
			cardholder_name: props.card.cardholder_name,
			...props.card.billing_address,
		};
	}
});

function onCancel() {
	emit('close');
}
async function onSave() {
	const payload = {
		number: fields.value.number,
		cvv: fields.value.cvv,
		expiration_month: fields.value.expiration_month,
		expiration_year: fields.value.expiration_year,
		cardholder_name: fields.value.cardholder_name,
		billing_address: {
			first_name: fields.value.first_name,
			last_name: fields.value.last_name,
			street_address: fields.value.street_address,
			extended_address: fields.value.extended_address,
			region: fields.value.region,
			locality: fields.value.locality,
			postal_code: fields.value.postal_code,
		},
	};
	try {
		saving.value = true;
		error.value = null;
		if (newCard.value) {
			try {
				const res = await paymentStore.createMethod(payload);

				if (res) {
					error.value = res;
					return;
				}
			} catch (err) {
				error.value = err;
				return;
			}
		} else {
			const res = await paymentStore.updateMethod(props.card.token, payload);
			if (res) {
				error.value = res;
				return;
			} else {
				console.warn(res);
			}
		}
		emit('close');
	} catch (err) {
		error.value = err;
		console.error(err);
	} finally {
		saving.value = false;
	}
}
function onFill() {
	fields.value = {
		...fields.value,
		first_name: agent.value.first_name,
		last_name: agent.value.last_name,
		street_address: agent.value.street,
		extended_address: agent.value.street2,
		locality: agent.value.city,
		region: agent.value.state,
		postal_code: agent.value.postal_code,
	};
}
</script>
