<template>
	<div v-if="domainDashboardGraph?.length > 0" id="domainChart" />
	<ProgressBar v-else class="w-full" />
</template>

<script setup>
import Highcharts from 'highcharts';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import { onMounted, onUpdated } from 'vue';
import ProgressBar from '@/components/ui/ProgressBar';
import useDomainStore from '@/stores/agent/agent-domains';
import { storeToRefs } from 'pinia';

const domainStore = useDomainStore();
const { domainDashboardGraph } = storeToRefs(domainStore);

function drawGraph() {
	const chart = new Highcharts.Chart({
		chart: {
			type: 'area',
			renderTo: 'domainChart',
			height: 150,
		},
		credits: false,
		colors: ['rgba(255, 150, 0, 1)'],
		title: { text: null },
		legend: { enabled: false },
		plotOptions: {
			series: {
				fillColor: {
					// 4th arg refers to the length of the zero stop
					linearGradient: [0, 0, 0, 120],
					stops: [
						// rgba of the same color fyi
						[0, 'rgba(255, 150, 0, 1)'],
						[1, 'rgba(255, 150, 0, 0)'],
					],
				},
			},
		},
		xAxis: {
			categories: domainDashboardGraph.value.map(function (index) {
				return format(new Date(index.date), 'MMM do');
			}),
			tickInterval: 7,
		},
		yAxis: {
			title: {
				text: 'Page Views',
			},
		},
		series: [
			{
				data: domainDashboardGraph.value.map(function (index) {
					return index.page_views;
				}),
				name: 'Page Views',
			},
		],
	});
	chart.hideLoading();
	return chart;
}
onUpdated(() => {
	if (domainDashboardGraph.value) {
		drawGraph();
	}
});

onMounted(async () => {
	const today = new Date();
	const start = startOfDay(subDays(today, 30));
	const end = endOfDay(today);
	await domainStore.ensureDashboardGraph({
		start,
		end,
	});

	if (domainDashboardGraph.value) {
		drawGraph();
	}
});
</script>

<style scoped></style>
