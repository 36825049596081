<template>
	<figure class="relative flex flex-col items-center rounded bg-gray-200 p-2">
		<img
			:style="{
				maxWidth: `${width}px`,
				maxHeight: `${height}px`,
				aspectRatio: width / height,
			}"
			:src="displayedImage"
			:alt="altText"
			class="bg-checkerboard w-full border-4 border-solid border-gray-400 object-cover object-top"
			:class="roundingClass"
		/>

		<figcaption class="text-md mt-4 rounded bg-white bg-opacity-60 text-center text-black">
			<BaseAlert v-bind="alertInfo">
				{{ alertInfo.text }}
			</BaseAlert>
		</figcaption>
	</figure>
</template>
<script setup>
import { computed } from 'vue';
import BaseAlert from '@/components/ui/BaseAlert';

const props = defineProps({
	currentlyActiveImageURL: { type: String, default: null },
	pendingImageOverrideURL: { type: String, default: null },
	inFlightImageURL: { type: String, default: null },
	height: { type: Number, default: 300 },
	width: { type: Number, default: 300 },
	fullWidth: { type: Boolean, default: false },
	altText: { type: String, default: 'current image' },
	roundingClass: { type: String, default: 'rounded' },
});

const displayedImage = computed(() => {
	if (props.inFlightImageURL) {
		return props.inFlightImageURL;
	}
	if (props.pendingImageOverrideURL) {
		return props.pendingImageOverrideURL;
	}
	return props.currentlyActiveImageURL;
});

const alertInfo = computed(() => {
	if (props.inFlightImageURL) {
		return { type: 'warn', dense: true, text: 'Your image is ready to be uploaded.' };
	}
	if (props.pendingImageOverrideURL) {
		return {
			type: 'success',
			dense: false,
			text: "Thanks! Your new photo has been successfully submitted. It may take 1-2 business days for review, and we'll e-mail the results. If your photo is approved it will go live immediately.",
		};
	}
	return { type: 'info', dense: true, text: 'Current Image' };
});
</script>
