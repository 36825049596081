<template>
	<fieldset :disabled="disabled" class="relative">
		<div class="w-full">
			<input
				v-bind="{ ...$attrs, value, id, type, step }"
				ref="input"
				class="peer w-full rounded border border-solid border-gray-500 pr-1 placeholder-transparent focus:border-gray-500 focus:ring-2 focus:ring-orange disabled:cursor-not-allowed disabled:border-gray-300 dark:bg-gray-400 dark:ring-gray-600 dark:focus:ring-orange-400 dark:enabled:bg-transparent sm:text-sm"
				:maxlength="maxCharacterCount"
				:placeholder="placeholder"
				@input="handleChange"
			/>
			<label
				:for="id"
				class="pointer-events-none absolute -top-4 left-0 text-xs transition-all peer-placeholder-shown:left-3 peer-placeholder-shown:top-1.5 peer-placeholder-shown:text-base peer-focus:-top-4 peer-focus:left-0 peer-focus:text-xs"
				:class="
					disabled
						? 'text-gray-500 dark:text-gray-400'
						: 'text-gray-600 dark:text-gray-300'
				"
			>
				{{ label }}
			</label>
			<span
				class="absolute -top-4 right-1 text-xs font-light text-gray-600 transition-all peer-placeholder-shown:right-3 peer-placeholder-shown:top-1.5 peer-placeholder-shown:text-base peer-focus:-top-4 peer-focus:right-1 peer-focus:text-xs"
			>
				<slot name="hint"></slot>
			</span>
		</div>

		<p v-if="maxCharacterCount" class="mt-2 w-full gap-2 text-right text-xs">
			{{ maxCharacterCount - value.length }} character(s) remaining
		</p>

		<p
			v-if="$slots.message"
			class="font-med !m-0 flex items-center text-xs text-red-600"
			:class="messageAbsolute ? 'absolute' : ''"
		>
			<slot name="message"></slot>
		</p>
	</fieldset>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';

const emit = defineEmits(['update:value']);
const props = defineProps({
	label: {
		type: String,
		required: true,
	},
	type: {
		type: String,
		default: 'text',
	},
	step: {
		type: [Number, String],
		default: null,
	},
	customID: {
		type: String,
		default: null,
	},
	value: {
		type: [String, Boolean],
		default: null,
	},
	placeholder: {
		type: String,
		default: null,
	},
	disabled: { type: Boolean, default: false },
	autofocus: { type: Boolean, default: false },
	maxCharacterCount: { type: Number, default: null },
	messageAbsolute: { type: Boolean, default: false },
});

const input = ref();
const id = computed(() => `${(props.customID || props.label).replace(/\s/g, '-')}-input`);

function handleChange(event) {
	emit('input', event.target.value);
	emit('update:value', event.target.value);
}
function focusInput() {
	input.value?.focus?.();
}
onMounted(() => {
	if (props.autofocus) {
		focusInput();
	}
});
defineExpose({
	focus: focusInput,
});
</script>

<style scoped></style>
