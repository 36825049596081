<template>
	<div class="relative inline-flex h-min">
		<button
			class="inline-flex h-min w-full outline-none ring-offset-2 transition focus:ring"
			:class="buttonClass"
			:aria-describedby="`${tooltipId.replaceAll(/\s/g, '_')}_tooltip`"
			@mouseover="
				showTooltip = true;
				isMouseOver = true;
			"
			@mouseleave="delayHideTooltip()"
			@focusin="showTooltip = true"
			@focusout="showTooltip = false"
			@keyup.esc="showTooltip = false"
			@click.stop="$emit('click')"
		>
			<div ref="slotEl">
				<slot />
			</div>

			<transition
				enter-active-class="pointer-events-none"
				enter-class="translate-y-0 opacity-0"
				enter-to-class="opacity-100"
				leave-class="opacity-100"
				leave-to-class="translate-y-0 opacity-0"
			>
				<span
					v-if="showTooltip"
					:id="`${tooltipId.replaceAll(/\s/g, '_')}_tooltip`"
					ref="tooltipEl"
					:style="{ transform: offsetStyle }"
					class="absolute z-[300] w-max max-w-xs rounded-md bg-gray-900 px-2 py-1 text-center text-white transition"
					:class="{
						topTooltip: position === 'top',
						bottomTooltip: position === 'bottom',
						leftTooltip: position === 'left',
						rightTooltip: position === 'right',
					}"
					role="tooltip"
				>
					{{ text }}
				</span>
			</transition>
		</button>
	</div>
</template>
<script setup>
import { computed, ref } from 'vue';

defineEmits(['click']);
const props = defineProps({
	position: {
		type: String,
		default: 'top',
	},
	text: {
		type: String,
		default: 'Tooltip',
	},
	tooltipId: {
		type: String,
		required: true,
	},
	buttonClass: {
		type: String,
	},
});

const showTooltip = ref(false);
const isMouseOver = ref(false);

const slotEl = ref(null);
const slotWidth = computed(() => slotEl.value?.clientWidth ?? 0);
const slotHeight = computed(() => slotEl.value?.clientHeight ?? 0);

const tooltipEl = ref(null);
const tooltipWidth = computed(() => tooltipEl.value?.clientWidth ?? 0);
const tooltipHeight = computed(() => tooltipEl.value?.clientHeight ?? 0);

const offsetStyle = computed(() => {
	if (props.position === 'right') {
		const finalOffsetY = (slotHeight.value - tooltipHeight.value) / 2;
		return `translate(${slotWidth.value + 10}px, ${finalOffsetY}px)`;
	}

	if (props.position === 'left') {
		const finalOffsetY = (slotHeight.value - tooltipHeight.value) / 2;
		return `translate(-${tooltipWidth.value + 10}px, ${finalOffsetY}px)`;
	}

	if (props.position === 'top') {
		const finalOffsetX = (slotWidth.value - tooltipWidth.value) / 2;
		return `translate(${finalOffsetX}px, -${tooltipHeight.value + 10}px)`;
	}

	if (props.position === 'bottom') {
		const finalOffsetX = (slotWidth.value - tooltipWidth.value) / 2;
		return `translate(${finalOffsetX}px, ${tooltipHeight.value + 10}px)`;
	}

	return 'translate(0px, 0px)';
});

function delayHideTooltip() {
	isMouseOver.value = false;
	setTimeout(() => {
		if (isMouseOver.value) {
			return;
		}
		showTooltip.value = false;
	}, 100);
}
</script>

<style scoped lang="scss">
.topTooltip {
	@apply after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-black after:content-[''];
}

.bottomTooltip {
	@apply after:absolute after:bottom-[100%] after:left-1/2 after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-black after:border-t-transparent after:content-[''];
}

.leftTooltip {
	@apply before:absolute before:bottom-1/2 before:left-[100%]  before:translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-black before:border-r-transparent before:content-[''];
}

.rightTooltip {
	@apply -top-0 before:absolute before:bottom-1/2 before:right-[100%]  before:translate-y-1/2 before:border-8 before:border-y-transparent before:border-l-transparent before:border-r-black before:content-[''];
}
</style>
