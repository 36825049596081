<template>
	<div class="columns-1 xl:columns-2">
		<div class="flex flex-col">
			<div id="timeGraphElement" />
			<div id="durationGraphElement" />
		</div>

		<div>
			<div class="flex flex-col items-stretch gap-4 py-4 pr-8">
				<h4 class="grow text-2xl font-semibold text-gray-700">Aggregated Stats</h4>

				<BaseSelect
					id="categorytype-graph"
					:value.sync="choice"
					label="Grouped By"
					:items="groupBy"
					class="grow"
					outlined
					show-label
					item-text-key="name"
					item-value-key="snake_case"
					value-type="object"
					@change="onChange()"
				/>
			</div>

			<div id="statsGraphElement" />
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, onUpdated, nextTick } from 'vue';
import highcharts from 'highcharts';
import { format, parseISO } from 'date-fns';

import BaseSelect from '@/components/ui/BaseSelect.vue';

const props = defineProps({
	stats: {
		type: Object,
		required: true,
	},
});

const choice = ref({ name: 'Source', snake_case: 'source' });
const groupBy = [
	{ name: 'Source', snake_case: 'source' },
	{ name: 'Keywords', snake_case: 'keywords' },
	{ name: 'Campaign', snake_case: 'campaign' },
	{ name: 'Referring Domain', snake_case: 'referring' },
	{ name: 'Landing Page', snake_case: 'landing_page' },
];

function onChange() {
	drawBarChart();
}

async function drawChart() {
	let graph = props.stats.graph;
	const call_series = Object.entries(graph)
		.map(value => {
			let callData = {
				x: parseISO(value[0]).setHours(0, 0, 0, 0),
				y: value[1].calls,
			};
			return callData;
		})
		.sort((a, b) => a.x - b.x);

	const duration_series = Object.entries(graph)
		.map(value => {
			let durationData = {
				x: parseISO(value[0]).setHours(0, 0, 0, 0),
				y: Number((value[1].duration / 60.0).toFixed(2)),
			};
			return durationData;
		})
		.sort((a, b) => a.x - b.x);
	await nextTick();
	new highcharts.Chart({
		chart: {
			type: 'line',
			renderTo: 'timeGraphElement',
			height: 200,
		},
		title: { text: 'Call Count' },
		legend: { enabled: false },
		credits: false,
		colors: ['rgba(255, 150, 0, 1)'],
		plotOptions: {
			series: {
				fillColor: {
					linearGradient: [0, 0, 0, 100],
					stops: [
						[0, 'rgba(255, 150, 0, 1)'],
						[1, 'rgba(255, 150, 0, 0)'],
					],
				},
				softThreshold: false,
			},
		},
		tooltip: {
			formatter: function () {
				return (
					format(this.x, 'MMM d, yyyy') + '<br /> Number of Calls: <b>' + this.y + '</b>'
				);
			},
		},
		xAxis: {
			type: 'datetime',
			dateTimeLabelFormats: {
				day: '%b %d %Y',
			},
		},
		yAxis: {
			title: { text: 'Number of Calls' },
			min: 0,
			maxPadding: 0,
			allowDecimals: false,
		},
		series: [
			{
				name: 'Number of Calls',
				type: 'area',
				data: call_series,
			},
		],
	});

	new highcharts.Chart({
		chart: {
			type: 'line',
			renderTo: 'durationGraphElement',
			height: 200,
		},
		title: { text: 'Call Duration' },
		legend: { enabled: false },
		credits: false,
		colors: ['rgba(255, 150, 0, 1)'],
		plotOptions: {
			series: {
				fillColor: {
					linearGradient: [0, 0, 0, 100],
					stops: [
						[0, 'rgba(255, 150, 0, 1)'],
						[1, 'rgba(255, 150, 0, 0)'],
					],
				},
				softThreshold: false,
			},
		},
		tooltip: {
			formatter: function () {
				return (
					format(this.x, 'MMM d, yyyy') + '<br /> Total Minutes: <b>' + this.y + '</b>'
				);
			},
		},
		xAxis: {
			type: 'datetime',
			dateTimeLabelFormats: {
				day: '%b %d %Y',
			},
		},
		yAxis: {
			title: { text: 'Total Call Duration' },
			min: 0,
			maxPadding: 0,
			allowDecimals: false,
		},
		series: [
			{
				name: 'Total Call Duration',
				type: 'area',
				data: duration_series,
			},
		],
	});
}

function drawBarChart() {
	let cat_series_repeat = [];
	let cat_series_first = [];
	let categories = [];
	function sortBy(key) {
		return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
	}

	if (props.stats) {
		const category_weight = Object.entries(props.stats.category[choice.value.snake_case]).map(
			value => {
				return {
					name: value[0],
					first: value[1].first,
					repeat: value[1].repeat,
					weight: (value[1].first || 0) + (value[1].repeat || 0),
				};
			}
		);
		categories = category_weight
			.concat()
			.sort(sortBy('weight'))
			.map(value => {
				return value.name;
			})
			.reverse();

		cat_series_first = category_weight
			.concat()
			.sort(sortBy('weight'))
			.reverse()
			.map((value, key) => {
				return {
					x: key,
					y: value.first,
					color: 'rgba(255, 150, 0, 1)',
				};
			});

		cat_series_repeat = category_weight
			.concat()
			.sort(sortBy('weight'))
			.reverse()
			.map((value, key) => {
				return {
					x: key,
					y: value.repeat,
					color: 'rgba(255, 150, 0, 0.5)',
				};
			});
		cat_series_repeat.concat().sort(sortBy('x'));
	}

	new highcharts.Chart({
		chart: {
			type: 'bar',
			renderTo: 'statsGraphElement',
			height: 300,
		},
		title: { text: '' },
		legend: { enabled: false },
		credits: false,
		tooltip: {
			shared: true,
		},
		xAxis: {
			categories: categories,
		},
		yAxis: {
			title: { text: 'Hits' },
		},
		plotOptions: {
			bar: {
				stacking: 'normal',
			},
		},
		series: [
			{
				name: 'Repeat Callers',
				data: cat_series_repeat,
			},
			{
				name: 'First Time Callers',
				data: cat_series_first,
			},
		],
	});
}

onUpdated(() => {
	drawChart();
	drawBarChart();
});

onMounted(() => {
	drawChart();
	drawBarChart();
});
</script>

<style></style>
