<template>
	<tr>
		<td>
			<span v-if="cancelled" class="-ml-2 mr-1 text-base">*</span>
			<span class="text-base" :style="wrap">{{ data.title }}</span>
		</td>
		<td class="text-base" :style="wrap">
			{{ detail ? detail : undefined }}
			<ul v-if="description" />
		</td>
		<td>
			<p :style="wrap" class="m-0 text-right text-base">{{ amount }}</p>
		</td>
	</tr>
</template>

<script setup>
import { computed } from 'vue';

import { moneyFormatter } from '@/utils';

const props = defineProps({
	data: { type: Object, required: true },
});

let cancelled = computed(() => props.data.cancelled);
let detail = computed(() => props.data.detail);
let description = computed(() => props.data.description);
let amount = computed(() => moneyFormatter.format(props.data.price));
let wrap = {
	wordWrap: 'break-word',
};
if (props.data.cancelled) {
	wrap = {
		textDecoration: 'line-through',
		wordWrap: 'break-word',
	};
}
</script>

<style scoped module lang="scss"></style>
