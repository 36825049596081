<template>
	<v-card flat>
		<v-card-title>{{ tabs[activeTab].humanTitle }} per AdGroup</v-card-title>
		<v-card-text>
			<p>{{ dateLabel }}</p>
		</v-card-text>

		<v-btn-toggle
			v-model="activeTab"
			:dense="$vuetify.breakpoint.mdAndDown"
			:class="$style.toggleGroup"
			tile
			active-class="activeBtn"
		>
			<v-btn
				v-for="tab of tabs"
				:key="tab.index"
				:small="$vuetify.breakpoint.mdAndDown"
				tile
				:class="$style.toggleButton"
				text
			>
				{{ $vuetify.breakpoint.mdAndDown ? tab.shortTitle : tab.humanTitle }}
			</v-btn>
		</v-btn-toggle>

		<div id="adwordsHighchart" />
	</v-card>
</template>

<script setup>
import { onMounted, ref, computed, onUpdated } from 'vue';
import highcharts from 'highcharts';
import { format } from 'date-fns';
import { storeToRefs } from 'pinia';

import useTimerangeStore from '@/stores/timerange';

const tabs = [
	{ index: 0, title: 'Impressions', humanTitle: 'Impressions', shortTitle: 'Impressions' },
	{ index: 1, title: 'Clicks', humanTitle: 'Clicks', shortTitle: 'Clicks' },
	{
		index: 2,
		title: 'Average Cost-Per-Click',
		humanTitle: 'Average Cost-Per-Click',
		shortTitle: 'Avg. Cost/Click',
	},
];

const props = defineProps({
	data: {
		type: Array,
		required: true,
	},
});

const timerangeStore = useTimerangeStore();
const { start } = storeToRefs(timerangeStore);

const activeTab = ref(0);

const dateLabel = computed(() => {
	if (start.value > 1) {
		return 'Last 30 days';
	}
	return format(start.value, 'MMMM yyyy');
});

function drawChart() {
	const activeTabTitle = tabs[activeTab.value].humanTitle;
	const series = props.data
		.filter(f => {
			return f[activeTabTitle] > 0;
		})
		.map(f => {
			return {
				name: f.AdGroupName,
				y: Number(f[activeTabTitle]),
			};
		})
		.sort((a, b) => a.y - b.y)
		.reverse();

	new highcharts.Chart({
		chart: {
			type: 'bar',
			renderTo: 'adwordsHighchart',
		},
		credits: false,
		title: { text: null },
		legend: { enabled: false },
		yAxis: {
			title: {
				text: activeTabTitle,
			},
		},
		xAxis: {
			type: 'category',
		},
		series: [
			{
				data: series,
			},
		],
	});
}

onUpdated(() => {
	drawChart();
});

onMounted(() => {
	drawChart();
});
</script>

<style scoped module lang="scss">
.wrapper {
	width: 100%;
}
.toggleGroup {
	width: 100%;
}
.toggleButton {
	grow: 1;
}
.activeBtn {
	background-color: blue;
}
</style>
