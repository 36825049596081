<template>
	<tr>
		<td>
			<h4 class="text-base">Payment by Comp Deduct</h4>
		</td>
		<td />
		<td>
			<p class="m-0 text-right text-base">{{ bigAmount }}</p>
		</td>
	</tr>
</template>

<script setup>
import { computed } from 'vue';

import { moneyFormatter } from '@/utils';

const props = defineProps({
	payment: { type: Object, required: true },
	receipt: { type: Object, required: true },
	internal: { type: Boolean, required: true },
});

const bigAmount = computed(
	() => moneyFormatter.format(props.payment.amount * (props.payment.is_refund ? -1 : 1))
	// .times(props.payment.is_refund ? -1 : 1)
	// .round(2)
	// .toFixed(2)
	// .toString()
);
</script>

<style scoped module lang="scss"></style>
