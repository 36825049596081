<template>
	<div class="flex items-end justify-stretch" div>
		<section class="mx-auto flex grow flex-col items-center text-center">
			<h3>Domains</h3>
			<hr class="w-full border-gray-300" />

			<p
				v-if="domainDashboardTotal"
				class="mt-5 text-center text-4xl font-medium text-gray-500"
			>
				{{ Number(domainDashboardTotal.total) }}
			</p>
			<ProgressBar v-else class="mx-auto my-2 w-1/3" />

			<p class="label text-center">Page Views</p>
			<img class="mx-auto block h-auto max-w-full" alt="" :src="domainsMock" />
			<BaseButton :to="{ name: 'domains' }" class="mt-1" color="gray" outline text
				>Domains Dashboard</BaseButton
			>
		</section>

		<section class="mx-auto flex grow flex-col items-center text-center">
			<h3>Campaigns</h3>

			<hr class="w-full border-gray-300" />

			<p v-if="views" class="mt-5 text-center text-4xl font-medium text-gray-500">
				{{ views }}
			</p>
			<ProgressBar v-else class="mx-auto my-2 w-1/3" />
			<p class="label text-center">Page Views</p>

			<img class="mx-auto block h-auto max-w-full" alt="" :src="qlpMock" />
			<BaseButton :to="{ name: 'campaigns' }" class="mt-1" color="gray" outline text>
				Campaigns Dashboard
			</BaseButton>
		</section>
		<section
			v-if="services.adwords"
			cols="12"
			md="4"
			class="flex grow flex-col items-center text-center"
		>
			<h3>Adwords</h3>

			<hr class="w-full border-gray-300" />

			<p
				v-if="adwordStats !== null"
				class="mt-5 text-center text-4xl font-medium text-gray-500"
			>
				{{ Number(adwordStats.report.compiled_data.clicks) }}
			</p>
			<ProgressBar v-else class="mx-auto my-2 w-1/3" />
			<p class="label text-center">clicks</p>

			<img class="mx-auto block h-auto max-w-full" alt="" :src="adwordsMock" />
			<BaseButton
				:to="{ name: 'adwords' }"
				class="mt-1"
				color="gray"
				outline
				rounded="md"
				text
				>Adwords Dashboard</BaseButton
			>
		</section>
	</div>
</template>

<script setup>
import { onMounted } from 'vue';
import { subDays } from 'date-fns';
import { storeToRefs } from 'pinia';

import domainsMock from '@/assets/images/domains_mock_graph.jpg';
import qlpMock from '@/assets/images/editor/qlp/qlp_mock_graph.jpg';
import adwordsMock from '@/assets/images/adwords_mock_graph.jpg';
import ProgressBar from '@/components/ui/ProgressBar';
import BaseButton from '@/components/ui/BaseButton';

import useAgentStore from '@/stores/agent/agent';
import useAdwordsStore from '@/stores/agent/agent-adwords';
import useDomainsStore from '@/stores/agent/agent-domains';
import useQlpStore from '@/stores/agent/agent-qlp';

defineProps({
	associateID: {
		type: String,
		required: true,
	},
	input: {
		Object,
	},
});

const agentStore = useAgentStore();
const adwordsStore = useAdwordsStore();
const domainsStore = useDomainsStore();
const qlpStore = useQlpStore();

const { services } = storeToRefs(agentStore);
const { domainDashboardTotal } = storeToRefs(domainsStore);
const { awacAccount, adwordStats } = storeToRefs(adwordsStore);
const { views } = storeToRefs(qlpStore);

onMounted(async () => {
	const params = {
		start: subDays(new Date(), 30),
		end: new Date(),
	};

	await domainsStore.ensureDashboardTotal({ ...params });

	if (services.value?.qlp) {
		qlpStore.getViews(params.start, params.end);
	}

	if (services.value?.adwords) {
		await adwordsStore.getAwacAccount();
		if (awacAccount.value?.account_active) {
			await adwordsStore.getAdwordStats(params.start, params.end);
		}
	}
});
</script>

<style scoped module lang="scss"></style>
