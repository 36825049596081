<template>
	<tr>
		<td>
			<h4 class="text-base">Credit for {{ refunds.category }}</h4>
		</td>
		<td>
			<p class="m-0 text-base">
				{{ amountRemainingString }} remaining of a {{ amountString }} credit
			</p>
		</td>
		<td>
			<p class="m-0 text-right text-base text-green-500">({{ amountAppliedString }})</p>
		</td>
	</tr>
</template>

<script setup>
import { computed } from 'vue';
import { moneyFormatter } from '@/utils';

const props = defineProps({
	refunds: { type: Object, required: true },
});

const amountString = computed(() => moneyFormatter.format(props.refunds.amount));
const amountRemainingString = computed(() =>
	// Big(props.refunds.amount_remaining).round(2).toFixed(2).toString()
	moneyFormatter.format(props.refunds.amount_remaining)
);
const amountAppliedString = computed(() => moneyFormatter.format(props.refunds.applied));
</script>

<style scoped module lang="scss"></style>
