<template>
	<v-card flat>
		<v-card-title>
			<h3>{{ tabs[activeTab].humanTitle }} per Day</h3>
		</v-card-title>
		<v-card-text cols="12">
			<v-btn-toggle
				v-model="activeTab"
				:class="$style.toggleGroup"
				background-color="rgba(0, 0, 0, 0.15)"
				active-class="activeBtn"
			>
				<v-btn v-for="tab of tabs" :key="tab.index" :class="$style.toggleButton" text>
					{{ tab.humanTitle }}
				</v-btn>
			</v-btn-toggle>
			<div ref="chartElement" />
		</v-card-text>
	</v-card>
</template>

<script setup>
import { onMounted, ref, onUpdated } from 'vue';
import highcharts from 'highcharts';
import { getTime, startOfDay, parseISO } from 'date-fns';
const tabs = [
	{ index: 0, title: 'Impressions', humanTitle: 'Impressions' },
	{ index: 1, title: 'Clicks', humanTitle: 'Clicks' },
];
const props = defineProps({
	data: {
		type: Array,
		required: true,
	},
	start: {
		type: String,
	},
	end: {
		type: String,
	},
	refreshes: {
		type: Array,
	},
});

const activeTab = ref(0);
const chartElement = ref(null);
function drawGraph() {
	const activeTabTitle = tabs[activeTab.value].humanTitle;
	let adgroupData = {};
	const combinedData = props.data.map(day => {
		const date = getTime(parseISO(day.date));
		day.data.forEach(adgroup => {
			if (!Object.prototype.hasOwnProperty.call(adgroupData, adgroup.AdGroupName)) {
				adgroupData[adgroup.AdGroupName] = [];
			}
			adgroupData[adgroup.AdGroupName].push({
				x: date,
				y: adgroup[activeTabTitle],
			});
		});

		return {
			x: date,
			y: day.data.reduce((sum, item) => sum + item[activeTabTitle], 0),
		};
	});

	const individualSeries = Object.entries(adgroupData)
		.map(([adgroup_name, stats_list]) => {
			if (stats_list.every(stat => stat.y === 0)) {
				return false;
			}
			return { name: adgroup_name, data: stats_list.sort((a, b) => a.x - b.x) };
		})
		.filter(adgroup => adgroup !== false);

	const faintSeries = [
		{
			name: 'Combined Stats',
			data: combinedData.sort((a, b) => a.x - b.x),
			type: 'area',
			// fillColor: '#AAAAAA44',
			fillOpacity: 0.3,
			lineWidth: 0,
			marker: {
				enabled: false,
			},
			states: {
				hover: {
					enabled: false,
				},
			},
		},
	];

	const combinedSeries = faintSeries.concat(individualSeries);
	const lines = props.refreshes.map(refresh => ({
		color: 'red',
		dashStyle: 'solid',
		value: getTime(startOfDay(parseISO(refresh.date))),

		width: 2,
		zIndex: 5,
		label: {
			text: '$' + refresh.amount + ' added to budget',
			align: 'center',
			verticalAlign: 'middle',
		},
	}));
	new highcharts.Chart({
		chart: {
			renderTo: chartElement.value,
		},
		credits: false,
		title: { text: null },
		xAxis: {
			type: 'datetime',
			max: getTime(startOfDay(parseISO(props.end))),
			min: getTime(startOfDay(parseISO(props.start))),
			plotLines: lines,
		},
		yAxis: {
			title: {
				text: activeTabTitle,
			},
		},
		series: combinedSeries,
	});
}
onUpdated(() => {
	drawGraph();
});
onMounted(() => {
	drawGraph();
});
</script>

<style scoped module lang="scss">
.wrapper {
	width: 100%;
}
.toggleGroup {
	width: 100%;
}
.toggleButton {
	grow: 1;
}
.activeBtn {
	background-color: blue;
}
</style>
