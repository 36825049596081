<template>
	<section class="h-full overflow-scroll rounded-md bg-white">
		<header class="sticky top-0 z-10 flex items-start justify-between bg-white p-2 shadow">
			<h2 class="shrink-0 text-2xl font-bold">Site Config</h2>
			<ProgressBar
				v-if="loading"
				class="text-center text-xl text-orange"
				text="Loading config..."
				dark
			/>
			<BaseToggle
				:value.sync="state.domain_transactions_enabled"
				label="Enable Domain Transactions"
				class="shrink-0 rounded border-4 border-solid p-2"
				:class="state.domain_transactions_enabled ? 'border-green-500' : 'border-red-500'"
			>
				<span class="flex items-center gap-2"> Enable Domain Transactions </span>
			</BaseToggle>
		</header>
		<div class="max-h-full overflow-auto p-2">
			<div class="flex justify-stretch gap-8">
				<DemoUsers :users.sync="state.demo_users" />
				<DemoDomains :domains.sync="state.demo_domains" />
			</div>
		</div>
		<footer
			class="sticky bottom-0 mt-8 flex w-full items-start justify-end gap-2 bg-gray-100 p-2"
		>
			<BaseButton ref="cancelButton" :disabled="loading || !configChanged" @click="reset">
				Undo Changes
			</BaseButton>
			<BaseButton
				ref="saveButton"
				:disabled="loading || !configChanged"
				color="primary"
				@click="saveConfig"
			>
				Update
			</BaseButton>
		</footer>
	</section>
</template>

<script setup>
import { computed, ref, reactive, watch } from 'vue';
import DemoDomains from '@/components/MirusControls/DemoDomains';
import DemoUsers from '@/components/MirusControls/DemoUsers';
import useConfigStore from '@/stores/site/config';
import ProgressBar from '@/components/ui/ProgressBar';
import BaseButton from '@/components/ui/BaseButton';
import BaseToggle from '@/components/ui/BaseToggle';

const configStore = useConfigStore();

const loading = ref(false);
const state = reactive({
	domain_transactions_enabled: false,
	demo_domains: [],
	demo_users: [],
});

const currentConfig = computed(() => configStore.config);
const configChanged = computed(() => {
	//compare all existing config properties to respective state property
	return Object.entries(currentConfig?.value ?? {}).some(([key, value]) => {
		return state[key] !== value;
	});
});
function reset() {
	//reset state properties for all existing config properties
	Object.entries(currentConfig?.value ?? {}).forEach(([key, value]) => {
		state[key] = value;
	});
}
async function saveConfig() {
	loading.value = true;
	const config = {};
	//loop over existing config properties and copy respective state property to new config
	Object.entries(currentConfig.value).forEach(([key]) => {
		config[key] = state[key];
	});
	await configStore.saveSiteConfig({ config });
	loading.value = false;
}
watch(() => currentConfig.value, reset, { immediate: true });
</script>

<style scoped></style>
