<template>
	<div v-if="domainName && (whois || loading)">
		<div v-if="domainError">
			<p v-if="domainError === 'unmatched'">
				Looks like {{ domainName }} is not owned by anyone. If you want to buy it, head over
				to the
				<AppLink :to="{ name: 'purchase', query: { domainName } }">
					Domain Purchasing Page </AppLink
				>.
			</p>
			<p v-else-if="domainError === 'owned'">
				It looks like you already own
				<AppLink :to="{ name: 'domains' }">{{ domainName }}</AppLink>
				!
			</p>
			<div class="flex justify-end space-x-2 p-2">
				<BaseButton v-if="whois" color="orange" @click="$emit('back')">
					Start Over
				</BaseButton>
			</div>
		</div>

		<ClonedAsyncData
			v-else
			v-slot="{ clonedData, execute }"
			:disabled="loading"
			:initial-state="{ ...whois }"
			:promise-to-call="retry"
		>
			<div v-if="clonedData" class="mb-4 flex space-x-4">
				<div class="w-1/2 rounded-md bg-white p-4">
					<p class="text-xl font-bold">Contact your registrar.</p>

					<address class="flex flex-col">
						<div>
							<span>This domain's registrar is: </span
							><b>{{ clonedData.registrar_name }}</b>
						</div>

						<div>
							Registrar Web Address:
							<a
								target="_new"
								rel="noopener noreferrer"
								title="This will open in a new window..."
								:href="clonedData.registrar_url"
							>
								{{ clonedData.registrar_url }}
							</a>
						</div>
						<div v-if="clonedData.admin_email">
							Domain Administrator E-Mail:
							<a :href="'mailto:' + clonedData.admin_email">{{
								clonedData.admin_email
							}}</a>
						</div>
					</address>

					<HelpPleaseNote class="col-span-2 col-start-2 row-start-3 m-0">
						<template #label>What is a "registrar"?</template>
						A domain name registrar is a company that manages the reservation of
						Internet domain names. A domain name registrar must be accredited by a
						generic top-level domain registry (like ".com" or ".net") or a country code
						top-level domain registry (like ".us" or ".ca"). A registrar operates in
						accordance with the guidelines of the designated domain name registries.

						<a
							href="http://en.wikipedia.org/wiki/Domain_registrar"
							target="_blank"
							rel="noopener noreferrer"
						>
							Read more about registrars here
						</a>
					</HelpPleaseNote>
				</div>

				<div class="rounded-md bg-white p-4">
					<p class="text-xl font-bold">Answer four questions:</p>
					<p class="mb-0 font-bold">1. Is the domain eligible?</p>
					<p>
						Domains must fulfill a 60-day grace period with their current registrar
						before being eligible to transfer.
					</p>

					<p class="mb-0 font-bold">2. Is the domain unlocked?</p>
					<p>
						You need to contact your registrar to get it unlocked and ready for
						transfer. After you unlock your domain, return here and you can continue the
						transfer.
					</p>

					<p class="mb-0 font-bold">3. Do you own the Administrator e-mail address?</p>
					<p>
						This is the information about who currently owns the domain, aka its
						Administrator.
					</p>

					<p class="mb-0 font-bold">4. What's the authorization code?</p>
					<p>
						This code (sometimes known as an EPP Code, or an Auth Code) will be provided
						by your registrar. If they e-mailed it to you, it&#39;s best to Cut &amp;
						Paste the code to avoid any mistakes.
					</p>
				</div>
			</div>
			<div class="rounded-md bg-white p-4">
				<p class="text-xl font-bold">Ready to Transfer?</p>

				<div>
					Does the domain fulfill the 60-day grace period:
					<ProgressBar v-if="loading" class="!inline-flex !w-auto" />

					<template v-else>
						<b v-if="gracePeriodfulfilled" class="text-green-600">
							60-day grace period fulfilled!
						</b>
						<b v-else class="text-red-500">
							Must wait {{ remainingGracePeriodDays }} days.
						</b>

						<BaseButton
							v-if="remainingGracePeriodDays > 0"
							class="ml-5"
							@click="execute"
						>
							Check for change
						</BaseButton>
					</template>
				</div>

				<div>
					Is the domain unlocked:

					<ProgressBar v-if="loading" class="!inline-flex !w-auto" />
					<template v-else>
						<b :class="clonedData.locked ? 'text-red-500' : 'text-green-600'">
							{{ clonedData.locked ? 'still locked' : 'unlocked!' }}
						</b>

						<BaseButton v-if="clonedData?.locked" class="ml-5" @click="execute">
							Check for change
						</BaseButton>
					</template>
				</div>
				<div>
					<CheckboxInput
						:checked.sync="hasAdmin"
						:color="hasAdmin ? 'green' : 'red'"
						name="hasAdmin"
					>
						I have confirmed ownership of the administrator email address
					</CheckboxInput>
				</div>
				<div>
					<CheckboxInput
						:checked.sync="hasAuth"
						:color="hasAuth ? 'green' : 'red'"
						name="hasAuth"
					>
						I have the auth code
					</CheckboxInput>

					<div class="mt-4 flex">
						<TextFieldInput
							v-if="hasAuth"
							id="auth-code"
							:value="authCode"
							label="Enter the auth code here"
							@update:value="changeAuthCode"
						/>
					</div>
				</div>
			</div>
		</ClonedAsyncData>
	</div>
	<ProgressBar v-else dark text="Checking domain info..." />
</template>

<script setup>
import { computed, watch, ref, watchEffect, inject } from 'vue';
import { storeToRefs } from 'pinia';

import useDomainsStore from '@/stores/agent/agent-domains';

import BaseButton from '@/components/ui/BaseButton';
import TextFieldInput from '@/components/ui/TextFieldInput';
import ProgressBar from '@/components/ui/ProgressBar.vue';
import CheckboxInput from '@/components/ui/CheckboxInput.vue';
import ClonedAsyncData from '@/components/ui/ClonedAsyncData.vue';
import AppLink from '@/components/ui/AppLink.vue';

import HelpPleaseNote from '@/components/MXEditor/help/HelpPleaseNote';

const emit = defineEmits(['update:auth-code', 'update:is-valid', 'back']);

const props = defineProps({
	authCode: { type: String, required: true },
	isValid: { type: Boolean, default: false },
});

const domainsStore = useDomainsStore();
const { whois } = storeToRefs(domainsStore);

const { domainName } = inject('domainName');

const hasAuth = ref(false);
const hasAdmin = ref(false);
const loading = ref(false);

const domainError = computed(() => {
	if (whois.value?.match === false) {
		return 'unmatched';
	} else if (whois.value?.owned) {
		return 'owned';
	}
	return null;
});

const remainingGracePeriodDays = computed(() => {
	return 60 - whois.value?.created_age;
});

const gracePeriodfulfilled = computed(() => {
	return whois.value?.created_age >= 60;
});

async function retry() {
	loading.value = true;
	await domainsStore.getWhois({ domainName: domainName.value });
	loading.value = false;
}

function changeAuthCode(code) {
	emit('update:authCode', code.replace(/\s/gi, ''));
}

watch(() => domainName, retry);
watchEffect(() => {
	if (
		!gracePeriodfulfilled.value ||
		(whois.value?.locked ?? true) ||
		!hasAuth.value ||
		!hasAdmin.value ||
		!props.authCode
	) {
		emit('update:is-valid', false);
	} else {
		emit('update:is-valid', true);
	}
});
</script>

<style scoped></style>
