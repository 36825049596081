<template>
	<ul class="mt-2 list-inside list-disc space-y-2">
		<li>
			First make sure you've followed the
			<a
				href="http://sfnet.opr.statefarm.org/agency/resources/as2kstaff/empproc/careerplug.shtml"
				target="_blank"
				>SF documentation</a
			>
			and set up your job listing site. This may be CareerPlug or other job listing sites
		</li>
		<li>
			Once you set up your job site, don't forget to add some job openings while you're there.
		</li>
		<li>
			Make note of the URL of your job listing page &mdash; for example, it may look something
			like this if you use CareerPlug
			<b>https://firstandlastname.sfagentjobs.com/account</b>.
		</li>
		<li>
			Next, you'll need to use the <HelpMCT /> to add the URL of your job listing, under the
			<b>Agent Profile</b> tab. Look for the "Employment Info" section near the bottom of the
			dialog:
			<br />
			<BaseTooltip text="MCT Dialog" tooltip-id="mct-dialog-link">
				<img
					max-width="1351"
					alt="agent profile tab"
					src="@/assets/images/editor/mct-dialog.jpg"
				/>
			</BaseTooltip>
		</li>
		<li>
			Check the radio button and fill in the URL of the job listing page you noted earlier.
		</li>
		<li>Click the <b>Save</b> button</li>
		<li>
			After making these changes, it will take 24-48 hours before the updates filter to your
			site.
		</li>
		<li>
			If you've waited more than 2 days without success, contact
			<HelpMYSFSupport :subject="`Job listings not visible for ${domain}`" />
		</li>
	</ul>
</template>

<script setup>
import { computed, getCurrentInstance } from 'vue';

import HelpMYSFSupport from '@/components/MXEditor/help/HelpMYSFSupport';
import HelpMCT from '@/components/MXEditor/help/HelpMCT';
import BaseTooltip from '@/components/ui/BaseTooltip';

const vm = getCurrentInstance().proxy;
const { $route } = vm;
const domain = computed(() => $route.params.domainName);
</script>
