<template>
	<div class="grid grid-cols-2 justify-center gap-8">
		<div>
			<h3 class="text-2xl font-semibold text-gray-700">{{ title }}</h3>
			<p v-if="!shouldShowDateRangeOnRight" :class="$style.rangeDescription">
				From {{ dateRangeLabel }}
			</p>
		</div>
		<div>
			<p v-if="shouldShowDateRangeOnRight" :class="$style.rangeDescription">
				From {{ dateRangeLabel }}
			</p>
			<form :class="{ 'pt-4': !shouldShowDateRangeOnRight }">
				<BaseSelect
					v-if="months"
					id="month"
					:value.sync="selectedAdwordsDate"
					:label="label || 'Select a date range'"
					:items="months"
					show-label
					outlined
					@update:value="handleMonthRangeChange"
				/>

				<BaseSelect
					v-else
					id="listbox"
					:value.sync="selectedQlpDate"
					:label="label || 'Select a date range'"
					:items="choices"
					show-label
					outlined
					@update:value="handleRangeChange"
				/>
			</form>
			<BaseDialog :value.sync="showDatepicker" dense max-width="320">
				<BaseDatePicker
					:value.sync="customDates"
					range
					:min="minStartDate"
					:max="maxEndDate"
				/>
				<template #actions="{ closeDialog }">
					<BaseButton value="cancel" text color="gray" @click="closeDialog()"
						>Cancel</BaseButton
					>
					<BaseButton
						:disabled="!customDatesReady"
						@click="
							onCustomConfirm();
							closeDialog();
						"
					>
						Apply Range
					</BaseButton>
				</template>
			</BaseDialog>
		</div>
	</div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { format, subDays, subMonths, subYears, parseISO } from 'date-fns';

import useTimerangeStore from '@/stores/timerange';

import BaseDatePicker from '@/components/ui/BaseDatePicker.vue';
import BaseSelect from '@/components/ui/BaseSelect.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseDialog from '@/components/ui/BaseDialog.vue';

const choices = ref([
	{ text: '30 days', value: 1 },
	{ text: '60 days', value: 2 },
	{ text: '90 days', value: 3 },
	{ text: '6 months', value: 6 },
	{ text: '1 year', value: 12 },
	{ text: 'Custom', value: 0 },
]);

defineProps({
	title: { type: String, required: true },
	label: { type: String, required: true },
	months: { type: Array },
	shouldShowDateRangeOnRight: { type: Boolean, default: false },
});

const timerangeStore = useTimerangeStore();
const {
	start,
	end,
	qlpRange: selectedQlpDate,
	adwordsRange: selectedAdwordsDate,
} = storeToRefs(timerangeStore);

const prevVal = ref(null);

const minStartDate = new Date(2000, 0, 1);
const maxEndDate = new Date();

const customDates = ref([]);
const customDatesReady = computed(() => customDates.value.length === 2);

const showDatepicker = computed({
	get() {
		return selectedQlpDate.value === 0;
	},
	set() {
		selectedQlpDate.value = prevVal.value;
	},
});

const dateRangeLabel = computed(() => {
	return `${format(start.value, 'MMM d, yyyy')} to ${format(end.value, 'MMM d, yyyy')}`;
});

function onCustomConfirm() {
	const dates = customDates.value.sort(
		(a, b) => Date.parse(new Date(a.date)) - Date.parse(new Date(b.date))
	);
	start.value = dates[0];
	end.value = dates[1];
	const customValue = { start: start.value, end: end.value };
	choices.value = [...choices.value, { text: dateRangeLabel.value, value: customValue }];
	selectedQlpDate.value = customValue;
}

function handleRangeChange(newVal) {
	prevVal.value = selectedQlpDate.value;

	if (newVal !== 0) {
		switch (newVal) {
			case 1:
				start.value = subDays(new Date(), 30);
				break;
			case 2:
				start.value = subDays(new Date(), 60);
				break;
			case 3:
				start.value = subDays(new Date(), 90);
				break;
			case 6:
				start.value = subMonths(new Date(), 6);
				break;
			case 12:
				start.value = subYears(new Date(), 1);
				break;
			default:
				start.value = newVal.start;
				end.value = newVal.end;
				selectedQlpDate.value = newVal;
				return;
		}
		end.value = new Date();
	}
	selectedQlpDate.value = newVal;
}

function handleMonthRangeChange(newVal) {
	prevVal.value = selectedAdwordsDate.value;
	end.value = parseISO(newVal.value.end);
	start.value = parseISO(newVal.value.start);
	selectedAdwordsDate.value = newVal;
}
</script>

<style scoped module lang="scss">
.wrapper {
	width: 100%;
}

.rangeDescription {
	font-size: 0.875rem;
	line-height: 1.5;
}
</style>
