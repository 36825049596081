<template>
	<v-card class="mb-5" :loading="loading">
		<v-card-title>
			<h3 class="display-1 text-gray-600">Budget Details</h3>
		</v-card-title>

		<v-card-text v-if="!loading && accountStatus">
			<v-row class="mt-3 text-center" justify="space-around">
				<v-col cols="6" lg="3">
					<p>{{ budget }}</p>
					<p class="label caption mt-n3">Budget Remaining</p>
				</v-col>
				<v-col cols="6" lg="3">
					<p>{{ renewal }}</p>
					<p class="label caption mt-n3">Next Renewal</p>
				</v-col>
				<v-col cols="6" lg="3">
					<p>{{ daily }}</p>
					<p class="label caption mt-n3">Average Daily Spending</p>
				</v-col>
				<v-col cols="6" lg="3">
					<p>{{ days }}</p>
					<p class="label caption mt-n3">Estimated Time to Exhaust</p>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';

import useAdwordsStore from '@/stores/agent/agent-adwords';

const adwordsStore = useAdwordsStore();
const { accountStatus } = storeToRefs(adwordsStore);

const budget = computed(
	() => `$${Number(accountStatus.value.budget - accountStatus.value.spent).toFixed(2)}`
);
const renewal = computed(
	() =>
		`$${Number(accountStatus.value.monthly_budget).toFixed(2)} (in ${
			accountStatus.value.days_till_refresh
		} days)`
);
const daily = computed(() => `$${Number(accountStatus.value.average_spending_per_day).toFixed(2)}`);
const days = computed(() => `${accountStatus.value.days_till_spent} Day(s)`);

const loading = ref(true);
onMounted(async () => {
	await adwordsStore.getAccountStatus();
	loading.value = false;
});
</script>

<style scoped lang="scss"></style>
