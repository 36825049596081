<template>
	<fieldset
		class="relative inline-flex items-center gap-0.5 rounded-lg border border-gray-500 bg-white px-1 pb-0.5 shadow-lg dark:bg-gray-800"
	>
		<legend
			class="rounded border border-solid border-gray-500 bg-white px-0.5 text-sm font-bold uppercase dark:bg-gray-800"
		>
			{{ name }}
		</legend>
		<BaseSelect
			:id="`${id}_hours`"
			:label="`${id}_hours`"
			:value.sync="localHour"
			:items="hourOptions"
			value-type="number"
			class="min-w-max"
		/>
		<span class="font-black">:</span>
		<BaseSelect
			:id="`${id}_minutes`"
			:label="`${id}_minutes`"
			:value.sync="localMinutes"
			:items="minuteOptions"
			value-type="number"
			class="min-w-max"
		/>
		<BaseSelect
			:id="`${id}_ampm`"
			label="AM/PM"
			:value.sync="ampm"
			:items="ampmOptions"
			value-type="string"
			class="min-w-max"
		/>
	</fieldset>
</template>

<script setup>
import { computed, ref, watch, watchEffect } from 'vue';
import BaseSelect from '@/components/ui/BaseSelect';
import { convert12hrTo24hr, isAboveMax, isBelowMinimum } from '@/utils/MXEditor/time';

const emit = defineEmits(['update:hour', 'update:minutes']);

const props = defineProps({
	errors: { type: Array, default: () => [] },
	step: {
		type: Number,
		default: 15,
		validator: value => {
			if (!Number.isInteger(60 / value)) {
				console.error(`60 is not evenly divisible by ${value}`);
				return false;
			} else {
				return true;
			}
		},
	},

	minimumMinutes: { type: Number, default: 0 },
	minimumHour: { type: Number, default: 0 },
	maxHour: { type: Number, default: 23 },
	maxMinutes: { type: Number, default: 59 },
	hour: { type: Number, default: 0 },
	minutes: { type: Number, default: 0 },

	disabled: { type: Boolean, default: false },
	id: { type: String, required: true },
	label: { type: String, default: null },
	name: { type: String, required: true },
});

const localHour = ref(props.hour > 12 ? props.hour % 12 : props.hour);
const localMinutes = ref(props.minutes);
const ampm = ref(props.hour < 12 ? 'am' : 'pm');

const hourOptions = computed(() => {
	const options = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
	return options.map(hourOption => {
		const hourAs24 = convert12hrTo24hr({ hourAs12: hourOption, ampm: ampm.value });

		const isBelow = isBelowMinimum({
			minimumHour: props.minimumHour,
			minimumMinutes: props.minimumMinutes,
			hour: hourAs24,
			minutes: localMinutes.value,
		});
		const isAbove = isAboveMax({
			maxHour: props.maxHour,
			maxMinutes: props.maxMinutes,
			hour: hourAs24,
			minutes: localMinutes.value,
		});
		const disabled = isBelow || isAbove;
		return { value: hourOption, disabled, text: hourOption };
	});
});
const minuteOptions = computed(() => {
	const options = [];
	const hourAs24 = convert12hrTo24hr({ hourAs12: localHour.value, ampm: ampm.value });
	for (let minute = 0; minute < 60; minute += props.step) {
		const isBelow = isBelowMinimum({
			minimumHour: props.minimumHour,
			minimumMinutes: props.minimumMinutes,
			hour: hourAs24,
			minutes: minute,
		});
		const isAbove = isAboveMax({
			maxMinutes: props.maxMinutes,
			maxHour: props.maxHour,
			hour: hourAs24,
			minutes: minute,
		});
		const disabled = isBelow || isAbove;

		options.push({ value: minute, disabled, text: minute.toString().padStart(2, '0') });
	}

	return options;
});
const ampmOptions = computed(() => {
	const hourAs24pm = convert12hrTo24hr({ hourAs12: localHour.value, ampm: 'pm' });
	const hourAs24am = convert12hrTo24hr({ hourAs12: localHour.value, ampm: 'am' });

	const amBelowMin = isBelowMinimum({
		minimumHour: props.minimumHour,
		minimumMinutes: props.minimumMinutes,
		hour: hourAs24am,
		minutes: localMinutes.value,
	});

	const pmAboveMax = isAboveMax({
		maxHour: props.maxHour,
		maxMinutes: props.maxMinutes,
		hour: hourAs24pm,
		minutes: localMinutes.value,
	});

	return [
		{
			value: 'am',
			text: 'AM',
			disabled: amBelowMin,
		},
		{
			value: 'pm',
			text: 'PM',
			disabled: pmAboveMax,
		},
	];
});

function handleChange() {
	emit('update:hour', Number(convert12hrTo24hr({ hourAs12: localHour.value, ampm: ampm.value })));
	emit('update:minutes', Number(localMinutes.value));
}
watch([localHour, localMinutes, ampm], handleChange);

watchEffect(() => {
	const hourAs24 = convert12hrTo24hr({ hourAs12: localHour.value, ampm: ampm.value });
	const minHour = props.minimumHour > 12 ? props.minimumHour % 12 : props.minimumHour;
	const maxHour = props.maxHour > 12 ? props.maxHour % 12 : props.maxHour;
	if (
		isBelowMinimum({
			minimumHour: props.minimumHour,
			minimumMinutes: props.minimumMinutes,
			hour: hourAs24,
			minutes: localMinutes.value,
		})
	) {
		localHour.value = minHour;
		localMinutes.value = props.minimumMinutes;
	}

	if (
		isAboveMax({
			maxHour: props.maxHour,
			maxMinutes: props.maxMinutes,
			minutes: localMinutes.value,
			hour: hourAs24,
		})
	) {
		localHour.value = maxHour;
		localMinutes.value = props.maxMinutes;
	}
});

defineExpose({
	localHour,
	localMinutes,
	ampm,
	hourOptions,
	minuteOptions,
	ampmOptions,
});
</script>
