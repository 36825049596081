<template>
	<ProgressBar v-if="loading" dark text="Retrieving domain list" class="text-xl font-bold" />
	<div v-else class="rounded-md bg-white p-2">
		<h3 class="py-3 text-center text-2xl font-semibold">Active Domains</h3>
		<BaseExpansionList :items="movableDomains" focusable>
			<template #header="{ name, active }">
				<div class="flex grow pr-4">
					<strong class="grow">{{ name }} </strong>
					<div
						class="shrink rounded-full border px-2"
						:class="
							active
								? 'border-green-700 bg-green-100 text-green-700'
								: 'border-red-700 bg-red-100 text-red-700'
						"
					>
						{{ active ? 'Active' : 'Canceled' }}
					</div>
				</div>
			</template>
			<template #content="{ name, active }">
				<RegistrarCard v-if="active" v-bind="{ name }" />
				<div v-else class="p-4 text-center">
					This domain is no longer active, but may still be owned by Mirus. Try searching
					for the domain out at the Registrars.
				</div>
			</template>
		</BaseExpansionList>
	</div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import RegistrarCard from '@/components/MirusControls/RegistrarCard';
import { storeToRefs } from 'pinia';
import useDomainsStore from '@/stores/agent/agent-domains';
import BaseExpansionList from '@/components/ui/BaseExpansionList.vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';

const domainStore = useDomainsStore();
const { movableDomains } = storeToRefs(domainStore);

const loading = ref(true);

onMounted(async () => {
	if (!movableDomains.value) {
		await domainStore.getMovableDomains();
	}
	loading.value = false;
});
</script>
