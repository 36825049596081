<template>
	<section v-if="guidelines" class="rounded bg-white p-4">
		<h5 class="text-xl font-bold">Ensure your photo meets these guidelines:</h5>

		<ul class="list-inside list-disc">
			<li v-for="guideline in guidelines" :key="guideline" v-html="guideline" />
		</ul>

		<HelpPleaseNote
			v-if="['agent_avatar', 'staff_avatar'].includes(overrideType)"
			:heading-level="6"
		>
			This is a partial list of the most common guidelines &mdash; a photo can be rejected for
			any violations of the
			<a
				href="http://sfnet.opr.statefarm.org/agency/marketing/digital_marketing/agent_microsite/photo_guidelines.shtml"
				target="_blank"
			>
				ABS documentation
			</a>
			. We'll review the photo and send the results by e-mail within 2 business days.
			<br />
			<br />Photos uploaded here, if approved
			<b>will not</b>
			change the
			<HelpEL /> or affect <HelpMicrosite />.
		</HelpPleaseNote>
		<template
			v-if="
				[`office_banner`, `office_jobs_image`, `office_mission_image`].includes(
					overrideType
				)
			"
		>
			<p class="text-sm text-gray-600">
				<strong>Please note:</strong>
				These are the primary guidelines listed in the
				<a
					href="http://sfnet.opr.statefarm.org/agency/marketing/digital_marketing/m1_web_marketing/guidelines.shtml"
					target="_blank"
				>
					ABS documentation
				</a>
				. You will be notified by email within 2 business days if a submission is denied.
			</p>
		</template>
		<p class="text-sm text-gray-600">
			<strong>*</strong>
			For all photos uploaded in the
			<span class="font-bold capitalize">{{ editorStore.productType }} Editor </span> - it is
			the Agent's responsibility to obtain and save authorization photo releases for all
			non-agent individuals (including agent team members) that are shown on your
			<span class="font-bold capitalize">{{ editorStore.productType }} site</span>. The
			authorization form needs to be provided and retained by the agent office.
		</p>
		<p class="mb-0 text-xs text-gray-800">
			By uploading this image, you agree to the above guidelines.
		</p>
	</section>
</template>

<script setup>
import { computed } from 'vue';
import HelpPleaseNote from '@/components/MXEditor/help/HelpPleaseNote';
import HelpEL from '@/components/MXEditor/help/HelpEL';
import HelpMicrosite from '@/components/MXEditor/help/HelpMicrosite';
import useEditorStore from '@/stores/editor';

const props = defineProps({
	width: { type: Number, required: true },
	height: { type: Number, required: true },
	name: { type: String, default: undefined },
	disabled: { type: Boolean, default: true },
	errors: { type: Array, default: () => [] },
	overrideType: {
		type: String,
		validator: prop => {
			const options = [
				'agent_avatar',
				'staff_avatar',
				'office_banner',
				'jobs_team_images',
				'mission_tab_images',
				'team_tab_images',
				'more_info_image',
			];
			const valid = options.includes(prop);
			if (!valid) {
				console.error(
					`${prop} is not a valid image type. Valid types include ${options.join(' ')}`
				);
			}
			return valid;
		},
	},
});

const editorStore = useEditorStore();
const guidelines = computed(() => {
	switch (props.overrideType) {
		case 'office_banner':
		case 'jobs_team_images':
		case 'mission_tab_images':
		case 'team_tab_images':
		case 'more_info_image':
			return [
				`The photo is <b>at least ${props.width}px wide X ${props.height} high</b>.`,
				`This photo <b>was not taken</b> from another website or found on Google, Yahoo or Bing.`,
				`You own the rights to this photo`,
				`The photo does not have faces of people blurred out`,
				`This is a photo, not an illustration.`,
				`The photo <b>completely fills</b> the rectangular area.`,
				`The photo does not have visible trademarked or copyrighted branding on clothing, artwork, background image, etc.`,
				`The photo doesn't use the State Farm logo and modify it in any way.`,
				`The photo does not have text superimposed on it, including your name.`,
				`The photo is not blurry or distorted.`,
				`The photo does not display State Farm awards or logos for those awards`,
			];

		case 'agent_avatar':
		case 'staff_avatar':
			if (!props.name?.trim()) {
				throw new Error('Supplied name is not valid!');
			}
			return [
				`The photo is <b>at least ${props.width}px wide X ${props.height}px high</b>.`,
				`The photo is of the upper-body, <b>not</b> full length.`,
				`This is a reasonably current photo of ${props.name}.`,
				`The <b>entire face</b> is visible (no sunglasses).`,
				`This photo is of <b>${props.name} alone</b>.`,
				`This is a photo, not an illustration.`,
				`This photo presents a <b>professional image</b>.`,
				`${props.name} is wearing business or business casual attire.`,
				`The photo <b>completely fills</b> the circular area.`,
			];
		default:
			return false;
	}
});
</script>

<style scoped></style>
