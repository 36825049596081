<template>
	<span>
		your office's
		<AppLink :href="micrositeUrl">
			<slot>State Farm agent microsite</slot>
		</AppLink>
	</span>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import useAgentStore from '@/stores/agent/agent';
import AppLink from '@/components/ui/AppLink.vue';

const agentStore = useAgentStore();
const { micrositeUrl } = storeToRefs(agentStore);
</script>
