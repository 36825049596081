<template>
	<NotificationBase v-bind="$props">
		<p>Congrats, Your new domain, {{ domain }}, has begun the transfer process!</p>
	</NotificationBase>
</template>

<script setup>
import NotificationBase from '@/components/notifications/NotificationBase';
import { computed } from 'vue';

const props = defineProps({
	body: { type: String, required: true },
	pk: { type: Number, required: true },
	urgency: { type: String, required: true },
	created: { type: String, required: true },
});

const domain = computed(() => JSON.parse(props.body).domain_name);
</script>
