<template>
	<div>
		<ProgressBar v-if="loading" class="w-full" />
		<p v-if="done">Request sent to refresh microsite data for {{ domainName }}!</p>
		<p v-else>Refreshing Microsite Data...</p>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';
import { useApi } from '@/composables/useApi';

const props = defineProps({
	domainName: {
		type: String,
		required: true,
	},
});

const loading = ref(true);
const done = ref(false);

onMounted(async () => {
	try {
		await useApi(`domains/${props.domainName}/crawl/`, {
			v3Agent: true,
			message: `There was an issue requesting a refresh for ${props.domainName}.`,
		});
		done.value = true;
	} finally {
		loading.value = false;
	}
});
</script>
