<template>
	<fieldset
		:class="isLoading ? 'relative grayscale' : ''"
		:disabled="isLoading"
		:role="isLoading ? 'presentation' : null"
	>
		<slot v-bind="{ execute, clonedData }">Loading...</slot>
		<span
			v-if="isLoading"
			class="pointer-none absolute bottom-0 top-0 z-50 h-full w-full cursor-progress"
		/>
	</fieldset>
</template>
<script setup>
import { ref } from 'vue';

import { useAsyncState, useCloned } from '@vueuse/core';

const props = defineProps({
	disabled: { type: Boolean, default: false },
	initialState: { type: [Array, Object, String, Number, Boolean], default: null },
	promiseToCall: { type: Function, required: true },
});

const localData = ref(props.initialState);

const { isLoading, execute } = useAsyncState(props.promiseToCall, props.initialState, {
	immediate: false,
	resetOnExecute: true,
	async onSuccess() {
		localData.value = props.initialState;
		await sync();
	},
});
const { cloned: clonedData, sync } = useCloned(localData, {
	clone: structuredClone,
	manual: true,
	deep: true,
	immediate: true,
});
</script>
