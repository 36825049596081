<template>
	<div
		class="flex flex-col justify-between gap-2 rounded-xl bg-gray-200 p-4 shadow dark:bg-gray-800 dark:shadow-black lg:flex-row lg:items-center"
	>
		<ValidationProvider
			v-slot="{ validate }"
			:name="`${name} open`"
			:vid="`${name} open`"
			slim
			rules="required"
			:allow-false="true"
		>
			<BaseToggle
				:value.sync="isOpen"
				@update:value="
					value => {
						validate(value);
						$emit('update:open', value);
					}
				"
			>
				<div class="mr-2 flex items-center">
					<h4 class="whitespace-nowrap text-lg font-bold">{{ name }} -&nbsp;</h4>

					<span v-if="name === 'After Hours'">
						{{ open ? 'Enabled' : 'Disabled' }}
					</span>
					<span v-else>
						{{ open ? 'Open' : 'Closed' }}
					</span>
				</div>
			</BaseToggle>
		</ValidationProvider>
		<template v-if="open && name !== 'After Hours'">
			<div class="flex w-full flex-col items-stretch gap-2 md:flex-nowrap md:items-end">
				<ValidationProvider
					v-for="(range, index) in ranges"
					v-slot="{ validate, errors }"
					:key="index"
					:rules="{ office_hours: ranges }"
					slim
					:name="`${name}-range-${index}`"
				>
					<TimeRangePicker
						:key="index"
						v-bind="{ index, name }"
						:start.sync="range.start"
						:close.sync="range.close"
						class="ml-auto w-full sm:w-auto"
						@update:start="validate(ranges)"
						@update:close="validate(ranges)"
					>
						<BaseButton
							v-if="index !== 0"
							color="red"
							:aria-label="`Remove additional ${name} hour range ${index}`"
							class="my-auto w-full"
							@click="removeRange(index)"
						>
							Delete range
						</BaseButton>
						<p v-if="errors.length > 0">{{ errors[0] }}</p>
					</TimeRangePicker>
				</ValidationProvider>
				<BaseButton
					v-if="open && name !== 'After Hours'"
					:aria-label="`Add range to ${name}`"
					class="mt-4 w-full self-end lg:w-auto"
					@click="addRange"
				>
					add range to {{ name }} <FAIcon icon="fa-plus" class="ml-2" />
				</BaseButton>
			</div>
		</template>
		<div v-if="name === 'After Hours'">
			<span>By Appointment</span>
		</div>
	</div>
</template>
<script setup>
import { ref } from 'vue';

import BaseToggle from '@/components/ui/BaseToggle.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

import TimeRangePicker from '@/components/MXEditor/TimeRangePicker.vue';

const emit = defineEmits('update:times');
const props = defineProps({
	times: { type: Array, default: () => [] },
	open: { type: Boolean, required: true },
	name: { type: String, required: true },
});

const isOpen = ref(props.open);

const ranges = ref(props.times);

function addRange() {
	ranges.value.push({ start: { hour: 9, minutes: 0 }, close: { hour: 17, minutes: 0 } });
}

function removeRange(index) {
	ranges.value.splice(index, 1);
	emitChange();
}
async function emitChange() {
	emit('update:times', ranges.value);
}
</script>
