<template>
	<div>
		<div class="mb-4 rounded-md bg-white p-4">
			<div class="mb-8 text-lg text-neutral-600">
				Do you own a domain that you want to transfer to your mysfdomain account? Enter the
				domain name below to get started!
			</div>

			<DomainValidator :searching="searching" @search="testDomain" />
		</div>

		<div v-if="!searching && previousSearches?.length > 0" class="mb-4 rounded-md bg-white p-4">
			<h3 class="text-lg text-neutral-600">Previous Transfer Attempts:</h3>
			<ul class="pl-0">
				<li
					v-for="item in previousSearches"
					:key="item"
					class="mt-2 flex items-stretch justify-start rounded border border-black bg-gray-100 first:mt-0"
				>
					<BaseButton :rounded="false" color="red" @click="removeHistoryItem(item)">
						<FAIcon class="text-xl" icon="xmark" />
					</BaseButton>
					<BaseButton
						text-alignment="left"
						:rounded="false"
						class="w-full justify-start"
						color="gray"
						text
						@click="handleItemClick(item)"
					>
						{{ item }}
					</BaseButton>
				</li>
			</ul>
		</div>

		<ProgressBar v-if="searching" class="w-full" />
	</div>
</template>

<script setup>
import { ref, nextTick, inject } from 'vue';
import { useLocalStorage } from '@vueuse/core';

import useDomainsStore from '@/stores/agent/agent-domains';

import ProgressBar from '@/components/ui/ProgressBar.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

import DomainValidator from '@/components/domains/DomainValidator';

const emit = defineEmits(['continue']);

const { domainName, updateDomainName } = inject('domainName');

const previousSearches = useLocalStorage('transfers', []);

const domainsStore = useDomainsStore();

const searching = ref(false);

async function testDomain() {
	if (!previousSearches.value?.includes(domainName.value)) {
		previousSearches.value = [domainName.value, ...previousSearches.value].slice(0, 5);
	}

	if (domainName.value?.trim()?.length > 0) {
		searching.value = true;
		await domainsStore.getWhois({ domainName: domainName.value });
		searching.value = false;

		emit('continue');
	}
}

function removeHistoryItem(domainName) {
	previousSearches.value = previousSearches.value.filter(item => item !== domainName);
}
async function handleItemClick(item) {
	if (item !== domainName.value) {
		updateDomainName(item);
		await nextTick();
	}

	await testDomain();
}
</script>

<style scoped></style>
