<template>
	<aside class="m-0 flex flex-col p-0 print:hidden">
		<section
			v-for="notification in siteNotifications"
			:key="notification.header"
			class="notification p-4"
			:class="notification.variant"
		>
			<strong class="text-2xl font-bold text-gray-600">{{ notification.header }}</strong>

			<p class="message" v-html="notification.message" />
		</section>

		<UserNotification
			v-if="showUpgradeScheduled"
			key="how-upgrade"
			class="bg-blue-700"
			:message="`Upgrade has been scheduled by agent for ${format(
				new Date(agent.upgrade_by_or_on),
				'MMM d, yyyy'
			)}`"
		/>
		<template v-if="visibleAgentNotifications.length > 0">
			<UserNotification
				v-for="notification in visibleAgentNotifications"
				:key="notification.id"
				v-bind="notification"
			/>
		</template>
	</aside>
</template>

<script setup>
import { computed } from 'vue';
import { format, isWithinInterval } from 'date-fns';
import { storeToRefs } from 'pinia';

import UserNotification from '@/components/notifications/UserNotification';
import useAgentStore from '@/stores/agent/agent';
import useUserStore from '@/stores/user';
import useNotificationStore from '@/stores/site/notifications';
import { useRoute } from 'vue-router/composables';

const agentStore = useAgentStore();
const userStore = useUserStore();
const notificationStore = useNotificationStore();

const { agent, notifications } = storeToRefs(agentStore);
const { isMirusUser } = storeToRefs(userStore);

const route = useRoute();
const siteNotifications = computed(() =>
	notificationStore.notifications
		?.filter(n => {
			return isWithinInterval(new Date(), {
				start: new Date(n.start),
				end: new Date(n.end),
			});
		})
		.filter(n => {
			return n.show_dashboard === true;
		})
);

const onDashboard = computed(() => route.name === 'dashboard');
const visibleAgentNotifications = computed(
	() =>
		notifications.value?.filter(item => {
			return (
				(!item.dashboard_only || onDashboard.value) && (item.persistant || !item.dismissed)
			);
		}) ?? []
);
const showUpgradeScheduled = computed(
	() => isMirusUser.value && !agent.value?.moved_to_new && agent.value?.upgrade_on_or_after
);
</script>
<style scoped lang="scss">
/*additional specificity to override vuetify :vomit:*/
section.notification {
	&.info {
		@apply bg-sky-100 text-slate-900 #{!important};
		a {
			@apply text-orange-900;
		}
	}

	&.success {
		@apply bg-green-50 text-green-800 #{!important};
	}

	&.error {
		@apply bg-red-100 text-red-900 #{!important};
	}

	&.warning {
		@apply bg-amber-50 text-yellow-700  #{!important};
	}
}
</style>
