<template>
	<div>
		<p>
			Adding
			{{ emailDescription }}
			will increase your monthly bill by
			<b>${{ increaseAmount }}/mo.</b>
		</p>
		<p>
			This will bring the total for this domain to
			<b>${{ domainTotal }}/mo</b>
		</p>

		<p v-if="shouldBill" ref="billingNotice">
			You will be charged ${{ increaseAmount }} immediately for the current billing cycle
		</p>
	</div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { startOfDay, compareAsc, addMonths, differenceInDays } from 'date-fns';
import useAgentStore from '@/stores/agent/agent';
import { storeToRefs } from 'pinia';

const props = defineProps({
	email: { type: Object },
	emails: { type: Array },
	existingEmails: { type: Array, required: true },
});
const billingNotice = ref(null);
const agentStore = useAgentStore();
const { agent } = storeToRefs(agentStore);

const nextBillDate = computed(() => {
	if (agent.value?.billing_day) {
		const startAsDate = new Date().setDate(agent.value?.billing_day ?? 1);
		const start = startOfDay(startAsDate);

		const afterStart = compareAsc(start, startOfDay(new Date())) < 0;

		return afterStart ? addMonths(start, 1) : start;
	} else {
		return undefined;
	}
});
const emailDescription = computed(() =>
	props.emails?.length > 1
		? `these ${props.emails.length} email redirects`
		: 'this email redirect'
);
const increaseAmount = computed(() => props.emails?.length ?? 1);
const shouldBill = computed(
	() => differenceInDays(nextBillDate.value || new Date(), new Date()) > 2
);
const domainTotal = computed(
	() => (props.existingEmails?.length ?? 0) + (props.emails?.length ?? 1) + 9
);
onMounted(async () => {
	await agentStore.ensureAgentData();
});
defineExpose({
	domainTotal,
	billingNotice,
	shouldBill,
	nextBillDate,
});
</script>

<style scoped></style>
