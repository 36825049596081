<template>
	<ProgressBar v-if="loading" text="Loading Payment Info..." />
	<div v-else-if="mode === 'list'" class="grid gap-8">
		<div class="flex flex-col gap-4">
			<h3 class="text-2xl font-bold text-gray-600">Selected Payment Method</h3>
			<template v-if="!info.card">
				<BaseAlert v-if="agent.is_agent_intern" type="error">
					No Payment Method Selected, please enter or select a payment method
				</BaseAlert>
				<BaseAlert v-else type="error">
					No Payment Method Selected, please enter a credit card, or enable Comp Deduct
				</BaseAlert>
			</template>
			<CompDeductItem v-if="info.card && info.card.card_type === 'eighttrack'" enabled />
			<PaymentMethodItem
				v-if="info.card && info.card.card_type !== 'eighttrack'"
				:card="info.card"
				:info="info"
				@edit="onEdit"
			/>
		</div>
		<div class="grid gap-4">
			<div class="flex items-center gap-4">
				<h3
					v-if="methods || (info.card && info.card.card_type !== 'eighttrack')"
					class="-mt-1 inline text-2xl font-bold text-gray-600"
				>
					Other Payment Methods
				</h3>
				<BaseButton color="primary" @click="onNew">
					<template #prepend>
						<FAIcon icon="fa-plus" />
					</template>
					Add New Credit Card
				</BaseButton>
			</div>
			<CompDeductItem
				v-if="
					!agent.is_agent_intern && (!info.card || info.card.card_type !== 'eighttrack')
				"
			/>
			<PaymentMethodItem
				v-for="card in unselectedMethods"
				:key="card.token"
				:card="card"
				@edit="onEdit"
			/>
		</div>
	</div>
	<div v-else-if="mode === 'edit'">
		<PaymentEditor :card="editingCard" @close="onListMode" />
	</div>
</template>

<script setup>
import { onMounted, computed, ref } from 'vue';
import { storeToRefs } from 'pinia';

import BaseButton from '@/components/ui/BaseButton.vue';
import ProgressBar from '@/components/ui/ProgressBar';
import PaymentMethodItem from '@/components/payment/PaymentMethodItem.vue';
import CompDeductItem from '@/components/payment/CompDeductItem.vue';
import PaymentEditor from '@/components/payment/PaymentEditor.vue';
import useAgentStore from '@/stores/agent/agent';
import usePaymentStore from '@/stores/agent/agent-payment';
import BaseAlert from '@/components/ui/BaseAlert.vue';

const agentStore = useAgentStore();
const paymentStore = usePaymentStore();
const { agent } = storeToRefs(agentStore);
const { methods, info } = storeToRefs(paymentStore);

const unselectedMethods = computed(() => methods.value.filter(card => !card.default));
const editingCard = computed(() => {
	if (methods.value && editing.value) {
		return methods.value.find(card => {
			return card.token === editing.value;
		});
	}
	return null;
});

const loading = ref(true);
const mode = ref('list');
const editing = ref(null);

onMounted(async () => {
	await paymentStore.ensureMethods();
	loading.value = false;
});

function onListMode() {
	mode.value = 'list';
}

function onEdit(cardToken) {
	editing.value = cardToken;
	mode.value = 'edit';
}

function onNew() {
	editing.value = 'new';
	mode.value = 'edit';
}
</script>
<style scoped>
.card-card {
	background-color: rgb(232, 232, 232);
}
</style>
