<template>
	<div>
		<HelpTerm>Image quality too low?</HelpTerm>
		You need an image at least <strong>{{ minWidth }}</strong
		>px wide X <strong>{{ minHeight }}</strong
		>px high.
		<br />
		Here's how you can check image quality before uploading:
		<ol class="mt-4 list-decimal">
			<li>
				Locate &amp;
				<strong>Right-click</strong>
				on the image
			</li>
			<li>
				Select the
				<strong>Properties</strong>
				option near the bottom (image <ImageNumber :number="1" /> )
			</li>
			<li>
				In the Properties window, select the
				<strong>Details</strong>
				tab (image <ImageNumber :number="2" /> )
			</li>

			<li>
				Verify the
				<strong>
					Image Width is at least {{ minWidth }} pixels and Height is at least
					{{ minHeight }} pixels
				</strong>
				(image <ImageNumber :number="3" /> ). If the image doesn't meet
				<strong>both</strong>
				the minimum height and width, the image cannot be used.
			</li>
		</ol>
		<div class="flex flex-col gap-2 xl:flex-row">
			<BaseTooltip
				v-for="image in images"
				:key="image.number"
				position="top"
				:tooltip-id="image.title"
				:text="image.title"
			>
				<ImageNumber :number="image.number" />
				<img
					:src="image.src"
					alt="select properties"
					class="mt-2 items-start justify-end text-right text-white"
				/>
			</BaseTooltip>
		</div>
	</div>
</template>

<script setup>
import checkSize1 from '@/assets/images/editor/check-image-size-1.png';
import checkSize2 from '@/assets/images/editor/check-image-size-2.png';
import checkSize3 from '@/assets/images/editor/check-image-size-3.png';
import ImageNumber from '@/components/MXEditor/help/ImageNumber';
import HelpTerm from '@/components/MXEditor/help/HelpTerm';
import BaseTooltip from '@/components/ui/BaseTooltip';

defineProps({
	minHeight: { type: Number },
	minWidth: { type: Number },
});

const images = [
	{ src: checkSize1, number: 1, title: 'Select properties' },
	{ src: checkSize2, number: 2, title: 'Select details' },
	{ src: checkSize3, number: 3, title: 'Verify Width & Height' },
];
</script>
<style lang="scss" scoped></style>
