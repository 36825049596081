<template>
	<div class="timePickerWrapper">
		<div v-if="$slots.default" class="col-start-1">
			<slot />
		</div>
		<TimePicker
			:id="`${name}-${index + 1}-start`"
			:label="`${name} hour range ${index + 1} start`"
			:hour.sync="localStart.hour"
			:minutes.sync="localStart.minutes"
			:minimum-hour="0"
			:minimum-minutes="0"
			:max-hour="close?.hour"
			:max-minutes="close?.minutes"
			:step="15"
			name="open"
			class="xs:col-start-1 sm:col-start-2"
			@update:hour="emitChange"
			@update:minutes="emitChange"
		/>
		<TimePicker
			:id="`${name}-${index + 1}-close`"
			:label="`${name} hour range ${index + 1} close`"
			:hour.sync="localClose.hour"
			:minutes.sync="localClose.minutes"
			:minimum-hour="start?.hour"
			:minimum-minutes="start?.minutes"
			:max-hour="23"
			:max-minutes="59"
			name="close"
			class="col-start-1 xs:col-start-3"
			@update:hour="emitChange"
			@update:minutes="emitChange"
		/>
	</div>
</template>
<script setup>
import { ref } from 'vue';

import TimePicker from '@/components/MXEditor/TimePicker.vue';

const emit = defineEmits(['update:start', 'update:close']);
const props = defineProps({
	index: { type: Number, required: true },
	name: { type: String, required: true },
	start: { type: Object, default: () => ({ hour: 0, minutes: 0 }) },
	close: { type: Object, default: () => ({ hour: 23, minutes: 45 }) },
});

const localStart = ref(props.start);
const localClose = ref(props.close);

async function emitChange() {
	emit('update:start', localStart.value);
	emit('update:close', localClose.value);
}
</script>
<style scoped>
.timePickerWrapper {
	@apply grid items-end justify-center gap-1 rounded-lg p-1;
	@apply border-2 border-gray-300 bg-transparent;
	@apply dark:border-gray-500 dark:shadow-sm dark:shadow-black md:gap-2;
}
</style>
