<template>
	<div v-if="discounts && discounts.length !== 0" class="flex justify-between px-4 py-2">
		<div class="mr-3 self-center">Discounts:</div>
		<ul class="list-none gap-2">
			<li
				v-for="discount in discounts"
				:key="discount.code"
				class="rounded-full bg-gray-300 p-2"
			>
				{{ discount.code }}: {{ moneyFormatter.format(discount.amount) }}
			</li>
		</ul>
	</div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { moneyFormatter } from '@/utils';

import { useApi } from '@/composables/useApi';

const discounts = ref(null);

async function onRefresh() {
	const { data } = await useApi('discounts/', {
		v3Agent: true,
	}).json();

	discounts.value = data.value;
}

onMounted(() => {
	onRefresh();
});
</script>
