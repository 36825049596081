<template>
	<section class="shrink-0 grow">
		<h3 class="text-lg">Demo users</h3>
		<hr class="w-full border-orange-500" />

		<p>These users have access to the MXEDITOR for demo domains listed here</p>

		<AgentSearch should-emit :show-history="false" @choose="handleChoose" />
		<ul class="mt-4 space-y-2 px-2">
			<li v-for="user in users" :key="user.associate_id" class="flex justify-between">
				<p>
					<span class="block font-medium">{{ user.title }}</span>
					<span v-if="user.subtext" class="text-sm">{{ user.subtext }}</span>
				</p>

				<button type="button" @click="removeFromList(user)">
					<FAIcon icon="times" />
				</button>
			</li>
		</ul>
	</section>
</template>

<script setup>
import AgentSearch from '@/components/common/AgentSearch';
const emit = defineEmits('update:users');
const props = defineProps({
	users: { type: Array, required: true },
});

async function handleChoose(user) {
	const { title, associate_id, subtext } = user;
	emit('update:users', [...props.users, { title, associate_id, subtext }]);
}
function removeFromList(user) {
	const { associate_id } = user;
	emit(
		'update:users',
		props.users.filter(u => u.associate_id !== associate_id)
	);
}
</script>

<style scoped></style>
