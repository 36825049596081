<template>
	<ProgressBar v-if="!domain" text="Loading domain info..." />

	<section v-else class="rounded bg-white shadow">
		<header class="flex w-full items-start justify-between gap-2 p-2 shadow">
			<div>
				<h3 class="text-xl font-bold text-gray-800">{{ domain.domain_name }}</h3>
				<p class="mb-0 text-sm text-gray-500">
					Status:
					{{ loading ? 'LOADING' : domain.auth_code ? 'Pending' : 'Waiting for details' }}
				</p>
			</div>

			<div class="mt-2 flex shrink-0 grow-0 items-start gap-4">
				<h5 v-if="aborting">Are you sure you want to cancel this transfer?</h5>

				<template v-if="aborting">
					<BaseButton dense color="red" @click="abort"> Absolutely serious </BaseButton>
					<BaseButton dense text @click="toggleAbort"> Not sure </BaseButton>
				</template>

				<template v-else>
					<BaseButton dense outline color="red" @click="toggleAbort">
						Cancel Transfer
					</BaseButton>
				</template>
			</div>
		</header>

		<EmailList v-if="!loading" class="p-2 text-gray-600" hide="false" :domain="domain" />
	</section>
</template>

<script setup>
import { ref } from 'vue';

import { useApi } from '@/composables/useApi';
import useDomainsStore from '@/stores/agent/agent-domains';

import ProgressBar from '@/components/ui/ProgressBar';
import BaseButton from '@/components/ui/BaseButton.vue';

import EmailList from '@/components/emails/EmailList';

const domainsStore = useDomainsStore();

const props = defineProps({
	domain: Object,
});

const loading = ref(false);
const aborting = ref(false);

async function abort() {
	loading.value = true;
	await useApi(`domains/${props.domain.domain_name}/`, {
		v3Agent: true,
		message: `There was an issue canceling your transfer.`,
	}).delete();
	await domainsStore.getDomains();
}

function toggleAbort() {
	aborting.value = !aborting.value;
}
</script>

<style scoped></style>
