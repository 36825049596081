<template>
	<div v-if="loading || m2Discount" class="py-4">
		<p v-if="loading">checking your discounts...</p>

		<p v-else-if="m2Discount">
			You have claimed M2 Discount code {{ m2Discount.code }} for ${{ m2Discount.amount }}. A
			credit will be applied to your next bill.
		</p>
	</div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { useApi } from '@/composables/useApi';

const discounts = ref(null);

async function onRefresh() {
	const { data } = await useApi(`discounts/`, {
		v3Agent: true,
	}).json();
	discounts.value = data?.value;
}

onMounted(() => {
	onRefresh();
});

const m2Discount = computed(() => {
	if (discounts.value) {
		return discounts.value.find(item => item.purpose === 'm2signup');
	}
	return null;
});

const loading = computed(() => {
	return !discounts.value;
});
</script>
