<template>
	<section class="flex items-center justify-start gap-4">
		<h3 class="whitespace-nowrap text-2xl font-semibold text-gray-700">Billing Snapshot:</h3>
		<div class="flex flex-initial gap-2">
			<div class="flex items-center justify-center gap-2 rounded-lg bg-gray-100 px-3 py-2">
				<div class="whitespace-nowrap">Current Rate:</div>
				<div v-if="currentExpenses" class="mb-0 font-semibold">
					${{ currentExpenses.current_rate }}
				</div>
				<ProgressBar v-else class="mx-auto" />
			</div>
			<FAIcon size="lg" icon="arrow-right" class="self-center" />
			<div class="flex items-center justify-center gap-2 rounded-lg bg-gray-100 px-3 py-2">
				<div class="whitespace-nowrap">Next Month&#39;s Rate:</div>
				<div v-if="currentExpenses" class="mb-0 flex gap-1 font-semibold">
					{{ moneyFormatter.format(currentExpenses.future_rate) }}
					<div v-if="Number(currentExpenses.diff) !== 0">
						<FAIcon :icon="`chevron-${currentExpenses.diff > 0 ? 'up' : 'down'}`" />
						{{ moneyFormatter.format(Math.abs(currentExpenses.diff)) }}
					</div>
				</div>
				<ProgressBar v-else class="mx-auto" />
			</div>
		</div>
		<BaseButton color="gray" class="ml-auto" @click="getCurrentExpenses">
			<template #prepend>
				<FAIcon icon="rotate-right" />
			</template>
			Refresh
		</BaseButton>
	</section>
</template>

<script setup>
import { onMounted, ref } from 'vue';

import { moneyFormatter } from '@/utils';

import { useApi } from '@/composables/useApi';

import useAlertStore from '@/stores/alerts';

import ProgressBar from '@/components/ui/ProgressBar';
import BaseButton from '@/components/ui/BaseButton.vue';

const currentExpenses = ref(null);
async function getCurrentExpenses() {
	currentExpenses.value = null;
	try {
		const { data } = await useApi('billing/calc/', { agent: true }).json();
		currentExpenses.value = data.value;
	} catch {
		const alertStore = useAlertStore();

		alertStore.addAlert({
			message: `We couldn't calculate your current billing expenses. Please try again later!`,
		});
	}
}
onMounted(() => {
	getCurrentExpenses();
});
</script>

<style scoped module lang="scss"></style>
