<template>
	<div>
		<div class="rounded-md bg-white p-2">
			<h3 class="text-2xl">Add new note</h3>
			<ProgressBar v-if="saving" class="h-[15vh]" />
			<ValidationObserver v-else ref="observer" v-slot="{ handleSubmit, invalid }">
				<form
					class="mb-3 mt-6 grid grid-cols-2 gap-5 px-3"
					@submit.prevent="handleSubmit(addNote)"
				>
					<ValidationProvider v-slot="{ errors }" name="Category" rules="required">
						<BaseSelect
							id="category"
							:value.sync="category"
							label="Category"
							:disabled="saving"
							:items="categories"
							show-label
							outlined
						>
							<template v-if="errors[0]" #message>
								<span>{{ errors[0] }}</span>
							</template>
						</BaseSelect>
					</ValidationProvider>

					<ValidationProvider v-slot="{ errors }" name="Note Text" rules="required">
						<TextFieldInput
							:value.sync="text"
							:disabled="saving"
							label="Note text"
							placeholder="Note text"
						>
							<template v-if="errors" #message>
								<span v-for="error in errors" :key="error">
									{{ error }}
								</span>
							</template>
						</TextFieldInput>
					</ValidationProvider>

					<ValidationProvider v-slot="{ errors }" name="FollowUpDate">
						<TextFieldInput
							:value.sync="followUpDate"
							label="Follow-up Date"
							type="date"
							:min="minDate"
						>
							<template v-if="errors[0]" #message>
								<span>{{ errors[0] }}</span>
							</template>
						</TextFieldInput>
					</ValidationProvider>

					<CheckboxInput :checked.sync="serviceInterruption" name="serviceInterruption">
						Service Interruption
					</CheckboxInput>

					<BaseButton
						type="submit"
						color="primary"
						:disabled="invalid || saving"
						class="col-span-2"
					>
						Add Note
					</BaseButton>
				</form>
			</ValidationObserver>
		</div>

		<ProgressBar v-if="loading" class="mt-5 !h-[15vh] rounded-md bg-white p-5" />
		<SortableTable
			v-else
			:headers="headers"
			:items="notes"
			title="Notes"
			item-unique-key="updated_at"
			show-pagination
			show-search
			show-title
			expand-all
			class="mt-5 rounded-md bg-white"
		>
			<template #item.followed_up_at="{ followed_up_at }">
				{{ followed_up_at ? format(new Date(followed_up_at), 'yyyy-MM-dd') : '' }}
			</template>
			<template #item.created_at="{ created_at }">
				{{ format(new Date(created_at), 'yyyy-MM-dd') }}
			</template>

			<template #item_expanded="{ text: textContent, service_failure }">
				<p class="mb-0 p-4">
					{{ textContent }}
					<span v-if="service_failure"> (Service Interruption) </span>
				</p>
			</template>
		</SortableTable>
	</div>
</template>

<script setup>
import { onMounted, computed, ref, watch } from 'vue';
import { format } from 'date-fns';

import { useApi } from '@/composables/useApi';

import TextFieldInput from '@/components/ui/TextFieldInput.vue';
import CheckboxInput from '@/components/ui/CheckboxInput.vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';
import SortableTable from '@/components/ui/SortableTable.vue';
import BaseSelect from '@/components/ui/BaseSelect.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

const categories = ref([]);
const notes = ref([]);
const pageCount = ref(0);
const observer = ref(null);
const category = ref(null);
const text = ref(null);
const followUpDate = ref(null);
const serviceInterruption = ref(false);

const saving = ref(false);
const loading = ref(true);

async function getNotesData() {
	const result = await useApi('notes/pages/', {
		agent: true,
		message: 'There was  an issue retrieving your notes data. Please try again later.',
	}).json();

	const data = result?.data?.value ?? {};
	categories.value = data?.categories;
	pageCount.value = data?.num_pages;
}
async function getNotes({ page = 1 }) {
	const result = await useApi(`notes/${page}/`, {
		agent: true,
		message: 'there was an issue retrieving your noted page. Please try again later.',
	}).json();
	notes.value = result?.data?.value.objects;
}

const minDate = computed(() => format(new Date(), 'yyyy-MM-dd'));

const page = ref(1);
const reset = ref(null);

const headers = [
	{ text: 'Assigned To', value: 'assigned_to', filterable: true },
	{ text: 'Follow Up By', value: 'follow_up_by', filterable: true },
	{ text: 'Followed Up At', value: 'followed_up_at' },
	{ text: 'Created At', value: 'created_at' },
	{ text: 'User', value: 'created_by', filterable: true },
	{ text: 'Category', value: 'category', filterable: true },
];

async function addNote() {
	saving.value = true;
	try {
		await useApi('notes/', {
			agent: true,
			message: `There was an issue saving that note! Please try again later.`,
		}).post({
			category: category.value,
			failure: serviceInterruption.value,
			text: text.value,
			date: followUpDate.value,
		});
		saving.value = false;
		reset.value = new Date();

		category.value = null;
		text.value = null;
		followUpDate.value = null;
		serviceInterruption.value = null;

		await fetchNotes();
		observer.value.reset();
	} finally {
		saving.value = false;
	}
}
async function fetchNotes() {
	loading.value = true;
	await getNotes({ page: page.value });
	loading.value = false;
}

watch(
	() => page.value,
	(p, oldP) => {
		if (p !== oldP) {
			fetchNotes();
		}
	}
);
onMounted(async () => {
	await getNotesData();
	await fetchNotes();
});
</script>

<style scoped module lang="scss">
.row {
	cursor: pointer;
}
.text {
	display: block;
	padding-top: 8px;
	padding-bottom: 8px;
	font-size: 0.875rem;
}
</style>
