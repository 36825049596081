<template>
	<div v-if="dismissed" class="bg-gray-100 p-4 dark:bg-gray-400">
		<div class="mx-auto flex max-w-[1075px] items-center justify-around">
			<img alt="" :src="m2ad_left" class="h-40 object-contain" />
			<div class="flex grow-0 flex-col justify-center">
				<h2
					class="whitespace-nowrap text-center text-2xl font-black text-black dark:text-gray-200"
				>
					M2 - Marketing Site
				</h2>
				<div class="mt-4 flex gap-1">
					<BaseButton rounded color="gray" class="mr-2" @click="onExpand">
						{{ loading ? 'Expanding...' : 'Expand' }}
					</BaseButton>
					<BaseButton color="primary" :to="{ name: 'domains' }">Read More</BaseButton>
				</div>
			</div>
			<hr class="h-40 w-0.5 bg-orange" />
			<ul class="text-sm font-medium">
				<li>Customized content and photos for your agency</li>
				<li>Includes team members bios and photos</li>
				<li>Responsive designs for mobile, tablet and desktop</li>
				<li>SEO optimized with curated insurance content</li>
			</ul>
			<img alt="" :src="m2ad_right" class="h-40 object-contain" />
		</div>
	</div>
	<div v-else class="container flex flex-col lg:flex-row">
		<div class="my-auto py-12 pl-8">
			<h2 class="mb-0 text-4xl font-bold text-orange">New Release:</h2>
			<h2 class="text-4xl font-bold text-black">M2 - Agent Marketing Site</h2>
			<p class="mt-2 text-lg font-bold italic text-black" style="line-height: 1.5rem">
				A complete re-design of the current M1 - Marketing Site + 4 brand new pages!
			</p>
			<h2 class="mt-2 text-xl font-bold text-orange">Includes all M1 features plus...</h2>
			<ul>
				<li>A true multi-page website</li>
				<li>Extremely fast performance scores</li>
				<li>Multiple homepage design options</li>
				<li>Integrated Google reviews with Call-to-Action links</li>
				<li>
					Individual Line of Business pages with agent-specific and localized pre-written
					content
				</li>
				<li>Integrated job listing details with Apply Now options</li>
				<li>Link individual pages to your online marketing campaigns</li>
				<li>Built-in industry leading SEO tactics</li>
			</ul>
			<div class="mt-4 flex gap-1">
				<BaseButton color="gray" :disabled="loading" @click="onDismiss">
					{{ loading ? 'Collapsing...' : 'Collapse' }}
				</BaseButton>
				<BaseButton color="primary" outline :to="{ name: 'domains' }">
					Read More
				</BaseButton>
				<BaseButton color="primary" dark to="/m2-video/"> Watch Video </BaseButton>
			</div>
		</div>
		<img
			alt=""
			:src="m2_hero"
			class="m-auto w-full max-w-[50vh] shrink object-contain lg:w-1/2"
		/>
	</div>
</template>

<script setup>
import { ref } from 'vue';

import m2_hero from '@/assets/images/editor/m2/m2_hero.webp';
import m2ad_left from '@/assets/images/editor/m2/m2ad_left.webp';
import m2ad_right from '@/assets/images/editor/m2/m2ad_right.webp';
import BaseButton from '@/components/ui/BaseButton.vue';
import useAgentStore from '@/stores/agent/agent';

const props = defineProps({
	dismissed: { type: Boolean, default: null },
	pk: { type: Number, required: true },
	urgency: { type: String, required: true },
	created: { type: String, required: true },
});

const { dismissNotification, expandNotification } = useAgentStore();
const loading = ref(false);

async function onDismiss() {
	loading.value = true;
	await dismissNotification({ note_id: props.id });
	loading.value = false;
}

async function onExpand() {
	loading.value = true;
	await expandNotification({ note_id: props.id });
	loading.value = false;
}
</script>
