<template>
	<ProgressBar v-if="!(stats && stats.header)" text="Loading Stats..." />
	<div v-else class="mt-4">
		<GlobalTimeRangeSelector title="Call Tracking Stats" />

		<div v-if="stats && stats.header" class="my-6 flex flex-row gap-4">
			<div class="data-pill bg-gray-100">
				<span class="text-lg font-medium leading-none">{{ stats.header.calls }}</span>
				<span class="text-md font-normal">Calls</span>
			</div>

			<div class="data-pill bg-gray-100">
				<span class="text-lg font-medium leading-none">{{ stats.header.first }}</span>
				<span class="text-md font-normal">First Time Callers</span>
			</div>

			<div class="data-pill bg-gray-100">
				<span class="text-lg font-medium leading-none">
					{{ stats.header.calls - stats.header.first }}
				</span>
				<span class="text-md font-normal">Returning Callers</span>
			</div>

			<div class="data-pill bg-gray-100">
				<span v-if="stats" class="text-lg font-medium leading-none">
					{{ formatDurationShort(stats.header.seconds) }}
				</span>
				<span class="text-md font-normal">Total Call Duration</span>
			</div>
		</div>

		<hr aria-orientation="horizontal" />
		<CallrailGraphs v-if="stats" :stats="stats" />
		<hr aria-orientation="horizontal" />

		<ProgressBar v-if="loading" text="Loading Call List..." />
		<SortableTable
			v-else
			:headers="[
				{ text: 'Time', value: 'start_time', sortable: true },
				{ text: 'Duration', value: 'duration', sortable: true },
				{ text: 'Customer Name', value: 'customer_name' },
				{ text: 'Customer Number', value: 'customer_phone_number' },
				{ text: 'City/State', value: 'formatted_customer_city_and_state' },
				{ text: 'Referring URL', value: 'referring_url' },
				{ text: 'Landing Page URL', value: 'landing_page_url' },
			]"
			:items="callList"
			item-unique-key="id"
			title="Call History"
			show-title
			show-pagination
			dense
			expand-all
		>
			<template #toolbar>
				<BaseButton
					v-if="agent"
					:href="`/api/agents/${
						agent.associate_id
					}/calltracking/download_report/?start=${format(
						start,
						'yyyy-MM-dd'
					)}&end=${format(end, 'yyyy-MM-dd')}`"
					label="Download Call History Report"
					color="green"
					dense
					outline
				>
					<FAIcon icon="cloud-download" />
					export calls to excel
				</BaseButton>
			</template>

			<template #item.start_time="{ start_time }">
				{{ formatTime(start_time) }}
			</template>

			<template #item.referring_url="{ referring_url }">
				{{ referring_url?.substring(0, 32) ?? 'None' }}...
			</template>

			<template #item.landing_page_url="{ landing_page_url }">
				<span class="w-10 max-w-10 truncate">
					{{ landing_page_url?.substring(0, 32) ?? 'None' }}...
				</span>
			</template>

			<template #item_expanded="row">
				<div v-if="row" class="p-4">
					<h3 class="text-bold mb-4 text-center text-lg">
						<span>Call Details for</span>

						<a
							v-if="agent.internal"
							target="_new"
							:href="`https://app.callrail.com/calls/${row.id}/`"
						>
							#{{ row.id }}
						</a>
						<span v-else> #{{ row.id }}</span>
					</h3>

					<div class="flex justify-start gap-4">
						<div class="rounded-md bg-white p-4">
							<h3 class="text-xl font-bold">Caller Info</h3>

							<table>
								<tr>
									<td class="font-medium">Customer Name</td>
									<td class="pl-2">{{ row.formatted_customer_name }}</td>
								</tr>
								<tr>
									<td class="font-medium">Customer Number</td>
									<td class="pl-2">{{ row.formatted_customer_phone_number }}</td>
								</tr>
								<tr>
									<td class="font-medium">Customer Location</td>
									<td class="pl-2">
										{{ row.formatted_customer_city_and_state }}
									</td>
								</tr>
								<tr>
									<td>
										<span class="pr-1 font-medium">Tracking Number</span>
										<BaseTooltip
											position="top"
											tooltip-id="tracking_number"
											text="The 'trackable' phone number dialed by the customer to reach your office."
										>
											<FAIcon icon="circle-info" class="text-blue-700" />
										</BaseTooltip>
									</td>
									<td class="pl-2">{{ row.tracking_number }}</td>
								</tr>
							</table>
						</div>

						<div class="rounded-md bg-white p-4">
							<h3 class="text-xl font-bold">Session Info</h3>
							<table>
								<tr>
									<td class="font-medium">Device</td>
									<td class="pl-2">{{ row.device_type }}</td>
								</tr>
								<tr>
									<td class="font-medium">Call Duration</td>
									<td class="pl-2">
										{{ formatDurationFromSeconds(row.duration) }}
									</td>
								</tr>
								<tr>
									<td>
										<span class="pr-1 font-medium">Call Duration</span>
										<BaseTooltip
											position="top"
											tooltip-id="tracking_number"
											text="A generic description of where the user found the trackable number.  Typical values include PPC, Organic, Direct or Unknown."
										>
											<FAIcon icon="circle-info" class="text-blue-700" />
										</BaseTooltip>
									</td>
									<td class="pl-2">
										{{ row.medium ? row.medium : 'Unknown' }}
									</td>
								</tr>
								<tr>
									<td class="font-medium">Last Requested URL</td>
									<td class="pl-2">
										{{
											row.last_requested_url
												? row.last_requested_url
												: 'Unknown'
										}}
									</td>
								</tr>
							</table>
						</div>

						<div class="grow space-y-2 rounded-md bg-white p-4">
							<div class="flex items-center">
								<span class="pr-1 font-medium"> Landing Page URL </span>
								<BaseTooltip
									position="top"
									tooltip-id="microsite_url"
									text="The microsite URL the caller first landed on."
								>
									<FAIcon icon="circle-info" class="text-blue-700" />
								</BaseTooltip>
							</div>

							<BaseTextArea
								label="Landing Page URL"
								:show-label="false"
								:rows="2"
								disabled
								:value="row.landing_page_url ? row.landing_page_url : 'Unknown'"
								class="ring-1 ring-gray-400"
							/>

							<div class="flex items-center">
								<span class="pr-1 font-medium"> Referring URL </span>
								<BaseTooltip
									position="top"
									tooltip-id="caller_url"
									text="The URL that referred the caller to your microsite."
								>
									<FAIcon icon="circle-info" class="text-blue-700" />
								</BaseTooltip>
							</div>
							<BaseTextArea
								label="Referring URL"
								:show-label="false"
								:rows="2"
								disabled
								:value="row.referring_url ? row.referring_url : 'Unknown'"
								class="w-full ring-1 ring-gray-400"
							/>
						</div>
					</div>

					<div class="flex justify-end">
						<BaseButton color="gray" @click="closeCallDetails"> Close </BaseButton>
					</div>
				</div>
			</template>
		</SortableTable>
	</div>
</template>

<script setup>
import { watch, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { format, formatDuration } from 'date-fns';

import useAgentStore from '@/stores/agent/agent';
import useAlertStore from '@/stores/alerts';
import useCalltrackingStore from '@/stores/agent/agent-calltracking';
import useTimeStore from '@/stores/time';

import BaseTooltip from '@/components/ui/BaseTooltip';
import BaseButton from '@/components/ui/BaseButton.vue';
import ProgressBar from '@/components/ui/ProgressBar';
import SortableTable from '@/components/ui/SortableTable.vue';
import BaseTextArea from '@/components/ui/BaseTextArea.vue';

import GlobalTimeRangeSelector from '@/components/common/GlobalTimeRangeSelector';
import CallrailGraphs from '@/components/calltracking/CallrailGraphs';

function formatDurationShort(seconds) {
	if (typeof seconds === 'number') {
		if (seconds === 0) {
			return '0';
		}
		if (seconds < 60) {
			return '<60s';
		}
		if (seconds < 3600) {
			return Math.floor((seconds % 3600) / 60) + 'm ' + (seconds % 60) + 's';
		}
		return `${Math.floor(seconds / 3600)}h ${Math.floor((seconds % 3600) / 60)}m`;
	} else {
		return seconds;
	}
}

function formatDurationFromSeconds(seconds) {
	if (seconds === null) {
		return '(in progress)';
	}
	return formatDuration(
		{
			seconds: seconds % 60,
			minutes: Math.floor((seconds % 3600) / 60),
			hours: Math.floor(seconds / 3600),
		},
		{ delimiter: ', ' }
	);
}

function formatTime(time) {
	return format(new Date(time), 'MMM d, yyyy h:mma');
}

const alertStore = useAlertStore();

const agentStore = useAgentStore();
const calltrackingStore = useCalltrackingStore();
const timeStore = useTimeStore();

const { agent } = storeToRefs(agentStore);
const { callList, stats } = storeToRefs(calltrackingStore);
const { start, end, choices } = storeToRefs(timeStore);

const loading = ref(true);

async function getCallList() {
	try {
		loading.value = true;
		await calltrackingStore.getSortedCallList({
			start: start.value,
			end: end.value,
		});

		loading.value = false;
	} catch (error) {
		console.error(error);
		alertStore.addAlert({
			message:
				"Uh oh, we couldn't get the sorted call list from the server! Please try again later.",
		});
	}
}

watch([start, end], async () => {
	calltrackingStore.getStats(start.value, end.value);
	getCallList();
});
onMounted(async () => {
	timeStore.setChoice(choices.value[0]);
	calltrackingStore.getStats(start.value, end.value);
	getCallList();
});
</script>

<style>
.data-pill {
	@apply flex grow items-center justify-center gap-1 rounded-md p-2;
}
</style>
