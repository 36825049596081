<template>
	<div role="radiogroup" class="flex items-stretch gap-2">
		<button
			v-for="option in options"
			:key="option.key"
			role="radio"
			type="button"
			:aria-checked="value === option.key"
			class="rounded-md p-4 ring-1 drop-shadow-md transition-all"
			:class="[
				value === option.key
					? 'bg-orange-500 ring-orange-800'
					: 'bg-white ring-black hover:bg-gray-200',
			]"
			@click="handleChange(option.key)"
			@keydown.enter.prevent="handleChange(option.key)"
			@keydown.space.prevent="handleChange(option.key)"
		>
			<slot v-bind="option" name="item" />
		</button>
	</div>
</template>

<script setup>
defineProps({
	value: {
		type: null,
		default: null,
	},
	options: {
		type: Array,
	},
});

const emit = defineEmits(['update:value']);

function handleChange(newValue) {
	emit('update:value', newValue);
}
</script>
