<template>
	<div class="h-full">
		<BaseButton v-if="buttonOnly" :disabled="disabled" :color="actionColor" @click="onOpen">
			<FAIcon v-if="actionIcon" class="mr-2" :icon="actionIcon" />
			{{ actionText }}
		</BaseButton>

		<div
			v-else
			class="flex h-full flex-col justify-between gap-1 !rounded-md bg-white p-4 dark:bg-gray-600 md:flex-row lg:gap-4"
		>
			<div class="space-y-2">
				<span v-if="title" class="text-xl font-bold leading-4">{{ title }}</span>
				<slot name="description">
					<p>description</p>
				</slot>
			</div>

			<div class="flex flex-col items-center justify-end gap-2 md:items-end">
				<div v-if="price" class="flex items-start">
					<div class="flex items-end justify-end">
						<span class="text-5xl font-bold tracking-tight">${{ price }}</span>
						<span class="ml-1 text-xl font-semibold">/month</span>
					</div>
				</div>
				<BaseButton :disabled="disabled" :color="actionColor" @click="onOpen">
					<FAIcon v-if="actionIcon" class="mr-2" :icon="actionIcon" />
					{{ actionText }}
				</BaseButton>
			</div>
		</div>
		<BaseDialog :value.sync="open" max-width="800">
			<template #header>
				<slot name="title">Confirm Billing Change</slot>
			</template>
			<ProgressBar
				v-if="loading"
				class="mt-2 items-stretch text-center"
				dark
				text="Retrieving billing details"
			/>

			<template v-if="summary">
				<slot name="explainer" :summary="summary">
					<p class="dark:text-gray-300">
						Please confirm these billing changes:
						<span v-if="emails">
							<br />
							<b>
								NOTE: this domain will have {{ emails }} email redirect(s), which
								will cost $1/mo each
							</b>
						</span>
					</p>

					<table class="block px-4 py-3 text-black dark:text-gray-300">
						<tbody class="block">
							<tr v-if="diff > 0">
								<td class="w-full">
									Monthly rate will
									<b>increase</b>
									by:
								</td>
								<td class="text-right">${{ Number(diff).toFixed(2) }}</td>
							</tr>
							<tr v-else-if="diff < 0">
								<td class="w-full">
									Monthly rate will
									<b>decrease</b>
									by:
								</td>
								<td class="text-right">${{ Number(-1 * diff).toFixed(2) }}</td>
							</tr>
							<hr class="gray my-2" />
							<tr>
								<td class="w-full">Payment Due Today:</td>
								<td class="text-right">
									${{ Number(summary.prorate).toFixed(2) || '0.00' }}
								</td>
							</tr>
							<hr class="gray my-2" />
							<tr>
								<td class="w-full">
									Total new monthly payment starting on
									{{ readable_date }}:
								</td>
								<td class="text-right">
									${{ Number(summary.new_rate).toFixed(2) }}
								</td>
							</tr>
						</tbody>
					</table>
					<BaseAlert class="mt-2" dense type="info">
						Note: The amounts above do not include any recently applied discounts or
						credits, but that info is available in the
						<AppLink :to="{ name: 'billing' }">Billing History</AppLink> tab.
					</BaseAlert>
				</slot>
			</template>

			<template #actions>
				<BaseButton color="gray" text @click="open = false"> Cancel </BaseButton>
				<BaseButton :disabled="disabled || loading || !summary" @click="confirm">
					Confirm
				</BaseButton>
			</template>
		</BaseDialog>
	</div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { parseISO, format } from 'date-fns';

import { useApi } from '@/composables/useApi';

import BaseButton from '@/components/ui/BaseButton';
import BaseDialog from '@/components/ui/BaseDialog';
import ProgressBar from '@/components/ui/ProgressBar.vue';
import BaseAlert from '@/components/ui/BaseAlert.vue';
import AppLink from '@/components/ui/AppLink.vue';

const emit = defineEmits('confirm');

const props = defineProps({
	options: { type: Object, required: true },
	title: { type: String, required: false },
	emails: { type: Number, required: false, default: 0 },
	actionText: { type: String, required: true },
	actionColor: { type: String, default: 'primary' },
	actionIcon: { type: String, required: false },
	disabled: { type: Boolean, required: false, default: false },
	buttonOnly: { type: Boolean, required: false, default: false },
	price: { type: Number, required: false },
});

const open = ref(false);
const summary = ref(null);
const loading = ref(false);
function confirm() {
	open.value = false;
	emit('confirm');
}

async function onOpen() {
	open.value = true;
	summary.value = null;
	loading.value = true;
	try {
		const { data } = await useApi(`billing/calc/?${new URLSearchParams(props.options)}`, {
			agent: true,

			message:
				'There was an issue opening your billing from the server! Please try again later.',
		}).json();
		summary.value = data.value;
	} catch (error) {
		console.error(error);
	} finally {
		loading.value = false;
	}
}
const readable_date = computed(() =>
	summary.value ? format(parseISO(summary.value.next_billing_day), 'MMMM do, yyyy') : null
);
const diff = computed(() =>
	summary.value ? summary.value.new_rate - summary.value.current_rate : null
);
</script>

<style></style>
