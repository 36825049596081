<template>
	<section :class="urgency" class="notification relative flex items-start p-4 font-medium">
		<header class="grow">
			<strong v-if="$slots.title" class="grow font-black">
				<slot name="title" />
			</strong>
			<slot />
		</header>
		<p class="m-0 mr-4 shrink-0 font-medium">
			{{ format(new Date(created), 'MMM d, yyyy') }}
		</p>
		<button
			class="flex grow-0 items-center rounded border-2 border-solid border-transparent p-0 px-2 font-medium uppercase transition-colors hover:border-current"
			:class="loading ? 'cursor-not-allowed text-gray-400' : ''"
			:disabled="loading"
			@click="onDismiss"
		>
			<FAIcon :icon="loading ? 'cog' : 'times'" :spin="loading" class="mr-2" size="lg" />
			dismiss
		</button>
	</section>
</template>

<script setup>
import { ref } from 'vue';
import { format } from 'date-fns';

import useAgentStore from '@/stores/agent/agent';

const props = defineProps({
	pk: { type: Number, required: true },
	urgency: { type: String, required: true },
	created: { type: String, required: true },
});

const { dismissNotification } = useAgentStore();
const loading = ref(false);

async function onDismiss() {
	loading.value = true;
	await dismissNotification({ note_id: props.pk });
	loading.value = false;
}

//  bg-yellow-50
</script>
