<template>
	<section>
		<div class="grid grid-cols-1 gap-2 lg:grid-cols-2 xl:grid-cols-3">
			<ImageEditor
				v-for="(item, index) in imageBlobs"
				:key="`${item}-${index}`"
				:ref="`imageEditor-${index}`"
				:blob-to-upload.sync="imageBlobs[index]"
				:image-index="index"
				:current-image="currentImages[index]"
				:pending-image="getPendingImage(index)"
				:override-type="overrideType"
				:height="height"
				:width="width"
				:trigger-on-mount="false"
				:name="`${name} image ${index + 1}`"
				:title="title"
				class="imageItem"
				@update:blob-to-upload="handleChange"
			/>

			<button
				v-if="imageBlobs.length + pendingImages.length < maxImageCount"
				class="h-full w-full rounded bg-gray-200 transition-colors hover:bg-gray-300 hover:text-orange-900"
				@click.prevent="addImage"
			>
				<FAIcon fixed-width icon="plus" size="6x" />
			</button>
		</div>
	</section>
</template>
<script setup>
import ImageEditor from '@/components/MXEditor/images/ImageEditor';
import { computed, ref } from 'vue';
import useEditorStore from '@/stores/editor';

import { storeToRefs } from 'pinia';

const emit = defineEmits(['update:value']);
const props = defineProps({
	value: { type: Array, required: true },
	currentImages: { type: Array, required: true },
	overrideType: { type: String, required: true },
	height: { type: Number, default: 1 },
	width: { type: Number, default: 1 },
	minImageCount: { type: Number, default: 1 },
	maxImageCount: { type: Number, default: 3 },
	name: { type: String, required: true },
	title: { type: String },
});

const editorStore = useEditorStore();

const { pendingOverrides } = storeToRefs(editorStore);

const imageBlobs = ref([...props.value]);

const pendingImages = computed(
	() =>
		pendingOverrides.value?.filter(override => override.override_type === props.overrideType) ??
		[]
);

function getPendingImage(index) {
	const pick = pendingImages.value.find(o => o.subkey === `.${index}`);
	return pick ? pick.value : undefined;
}

function handleChange() {
	emit('update:value', imageBlobs.value);
}
function addImage() {
	imageBlobs.value.push(undefined);
}
</script>
<style>
.imageItem {
	@apply h-full min-h-[5rem] w-full rounded border-2 border-solid object-contain;
}
</style>
