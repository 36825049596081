<template>
	<div>
		<BaseButtonGroup
			:buttons="[
				{ value: 'domain_name', text: 'Domains' },
				{ value: 'path', text: 'Landing Pages' },
				{ value: 'cmpid', text: 'Campaign IDs' },
			]"
			:value.sync="activeTab"
			class="w-full"
		/>

		<div id="qlpStatsChart" />
	</div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import highcharts from 'highcharts';
import BaseButtonGroup from '@/components/ui/BaseButtonGroup.vue';

const props = defineProps({
	data: {
		type: Array,
		required: true,
	},
	shouldDisableTitle: { type: Boolean, default: false },
	shouldShowUncampaigned: { type: Boolean, default: false },
});
const activeTab = ref('domain_name');

function drawChart() {
	const remappedChartData = (props?.data ?? []).reduce((acc, current) => {
		const currentId = current[activeTab.value] ?? 'No Campaign ID';

		if (!props.shouldShowUncampaigned) {
			if (!currentId || currentId === 'No Campaign ID') {
				return acc; // skip if flag is enabled
			}
		}

		const { page_views, quotestarts } = current;

		if (acc[currentId]) {
			acc[currentId]['page_views'] += page_views;
			acc[currentId]['quotestarts'] += quotestarts;
		} else if (page_views > 0 || quotestarts > 0) {
			acc[currentId] = {
				page_views,
				quotestarts,
				[activeTab.value]: currentId,
			};
		}

		return acc;
	}, {});

	const sortedChartData = Object.values(remappedChartData).sort(
		(a, b) => Number(b.page_views) - Number(a.page_views)
	);

	highcharts.setOptions({
		lang: {
			thousandsSep: ',',
		},
	});

	new highcharts.Chart({
		chart: {
			type: 'bar',
			renderTo: 'qlpStatsChart',
		},
		title: { text: null },
		legend: { align: 'center', verticalAlign: 'top' },
		plotOptions: {},
		tooltip: {
			shared: true,
		},
		xAxis: {
			categories: sortedChartData.map(data => data[activeTab.value]),
		},
		yAxis: {
			allowDecimals: false,
		},
		credits: false,
		series: [
			{
				name: 'Page Views',
				maxPointWidth: 50,
				data: sortedChartData.map(index => index.page_views),
				color: '#434348',
			},
			{
				name: 'Quote Starts',
				maxPointWidth: 50,
				data: sortedChartData.map(index => index.quotestarts),
				color: '#7cb5ec',
			},
		],
	});
}

onMounted(drawChart);

watch([() => props.shouldShowUncampaigned, () => props.data, () => activeTab.value], drawChart, {
	deep: true,
});
</script>
