<template>
	<v-data-table
		v-if="roundedItems"
		:headers="headers"
		:items="roundedItems"
		:items-per-page="6"
		:dense="$vuetify.breakpoint.mdAndDown"
		width="100%"
		:class="$style.table"
	/>
</template>

<script setup>
import { computed } from 'vue';

import { moneyFormatter } from '@/utils';

const props = defineProps({
	data: {
		type: Array,
		required: true,
	},
});

const items = computed(() => props.data.filter(item => item.Impressions > 0 && item.Clicks > 0));
const roundedItems = computed(() => {
	// for (let item of items.value) {
	// 	item.TopImpressionPercentage = Big(item.TopImpressionPercentage * 100)
	// 		.round(2)
	// 		.toFixed(2)
	// 		.toString();
	// 	item.Cost = Big(item.Cost).round(2).toFixed(2).toString();
	// }
	return items.value?.map(item => {
		const newItem = {
			...item,
		};
		newItem.TopImpressionPercentage = new Intl.NumberFormat('en-US', {
			style: 'percent',
		}).format(item.TopImpressionPercentage);

		// newItem.Cost = Big(item.Cost).round(2).toFixed(2).toString();
		newItem.Cost = moneyFormatter.format(item.Cost);
		newItem['Average Cost-Per-Click'] = moneyFormatter.format(item['Average Cost-Per-Click']);

		return newItem;
	});
});

const headers = [
	{
		text: 'AdGroup',
		align: 'left',
		sortable: false,
		value: 'AdGroupName',
	},
	{ text: 'Impressions', value: 'Impressions' },
	{ text: 'Clicks', value: 'Clicks' },
	{ text: 'Cost', value: 'Cost' },
	{ text: 'Avg. CPC', value: 'Average Cost-Per-Click' },
	{ text: 'Impr. (Top) %', value: 'TopImpressionPercentage' },
];
</script>

<style lang="scss" module>
.table {
	td,
	th {
		white-space: nowrap;
	}
}
</style>
