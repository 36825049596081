export const states = [
	{
		text: 'AL - Alabama',
		value: 'AL',
	},
	{
		text: 'AK - Alaska',
		value: 'AK',
	},
	{
		text: 'AS - American Samoa',
		value: 'AS',
	},
	{
		text: 'AZ - Arizona',
		value: 'AZ',
	},
	{
		text: 'AR - Arkansas',
		value: 'AR',
	},
	{
		text: 'CA - California',
		value: 'CA',
	},
	{
		text: 'CO - Colorado',
		value: 'CO',
	},
	{
		text: 'CT - Connecticut',
		value: 'CT',
	},
	{
		text: 'DE - Delaware',
		value: 'DE',
	},
	{
		text: 'DC - District Of Columbia',
		value: 'DC',
	},
	{
		text: 'FM - Federated States Of Micronesia',
		value: 'FM',
	},
	{
		text: 'FL - Florida',
		value: 'FL',
	},
	{
		text: 'GA - Georgia',
		value: 'GA',
	},
	{
		text: 'GU - Guam',
		value: 'GU',
	},
	{
		text: 'HI - Hawaii',
		value: 'HI',
	},
	{
		text: 'ID - Idaho',
		value: 'ID',
	},
	{
		text: 'IL - Illinois',
		value: 'IL',
	},
	{
		text: 'IN - Indiana',
		value: 'IN',
	},
	{
		text: 'IA - Iowa',
		value: 'IA',
	},
	{
		text: 'KS - Kansas',
		value: 'KS',
	},
	{
		text: 'KY - Kentucky',
		value: 'KY',
	},
	{
		text: 'LA - Louisiana',
		value: 'LA',
	},
	{
		text: 'ME - Maine',
		value: 'ME',
	},
	{
		text: 'MH - Marshall Islands',
		value: 'MH',
	},
	{
		text: 'MD - Maryland',
		value: 'MD',
	},
	{
		text: 'MA - Massachusetts',
		value: 'MA',
	},
	{
		text: 'MI - Michigan',
		value: 'MI',
	},
	{
		text: 'MN - Minnesota',
		value: 'MN',
	},
	{
		text: 'MS - Mississippi',
		value: 'MS',
	},
	{
		text: 'MO - Missouri',
		value: 'MO',
	},
	{
		text: 'MT - Montana',
		value: 'MT',
	},
	{
		text: 'NE - Nebraska',
		value: 'NE',
	},
	{
		text: 'NV - Nevada',
		value: 'NV',
	},
	{
		text: 'NH - New Hampshire',
		value: 'NH',
	},
	{
		text: 'NJ - New Jersey',
		value: 'NJ',
	},
	{
		text: 'NM - New Mexico',
		value: 'NM',
	},
	{
		text: 'NY - New York',
		value: 'NY',
	},
	{
		text: 'NC - North Carolina',
		value: 'NC',
	},
	{
		text: 'ND - North Dakota',
		value: 'ND',
	},
	{
		text: 'MP - Northern Mariana Islands',
		value: 'MP',
	},
	{
		text: 'OH - Ohio',
		value: 'OH',
	},
	{
		text: 'OK - Oklahoma',
		value: 'OK',
	},
	{
		text: 'OR - Oregon',
		value: 'OR',
	},
	{
		text: 'PW - Palau',
		value: 'PW',
	},
	{
		text: 'PA - Pennsylvania',
		value: 'PA',
	},
	{
		text: 'PR - Puerto Rico',
		value: 'PR',
	},
	{
		text: 'RI - Rhode Island',
		value: 'RI',
	},
	{
		text: 'SC - South Carolina',
		value: 'SC',
	},
	{
		text: 'SD - South Dakota',
		value: 'SD',
	},
	{
		text: 'TN - Tennessee',
		value: 'TN',
	},
	{
		text: 'TX - Texas',
		value: 'TX',
	},
	{
		text: 'UT - Utah',
		value: 'UT',
	},
	{
		text: 'VT - Vermont',
		value: 'VT',
	},
	{
		text: 'VI - Virgin Islands',
		value: 'VI',
	},
	{
		text: 'VA - Virginia',
		value: 'VA',
	},
	{
		text: 'WA - Washington',
		value: 'WA',
	},
	{
		text: 'WV - West Virginia',
		value: 'WV',
	},
	{
		text: 'WI - Wisconsin',
		value: 'WI',
	},
	{
		text: 'WY - Wyoming',
		value: 'WY',
	},
];

export const months = [
	{ value: '01', text: '01 - January' },
	{ value: '02', text: '02 - February' },
	{ value: '03', text: '03 - March' },
	{ value: '04', text: '04 - April' },
	{ value: '05', text: '05 - May' },
	{ value: '06', text: '06 - June' },
	{ value: '07', text: '07 - July' },
	{ value: '08', text: '08 - August' },
	{ value: '09', text: '09 - September' },
	{ value: '10', text: '10 - October' },
	{ value: '11', text: '11 - November' },
	{ value: '12', text: '12 - December' },
];

export default { states, months };
