<template>
	<div :loading="loading" type="text, image, text, image">
		<ProgressBar v-if="loading" />
		<template v-else>
			<h5 class="font-mono text-xl font-medium">Last EL export</h5>
			<pre v-if="lastExport">{{ lastExport }}</pre>
			<pre v-else>No record of last export.</pre>

			<template v-if="nextExport">
				<h5 class="mt-4 font-mono text-xl font-medium">Next EL export</h5>
				<pre>{{ nextExport }}</pre>
			</template>
		</template>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useApi } from '@/composables/useApi';
import ProgressBar from '@/components/ui/ProgressBar.vue';

const props = defineProps({
	domainName: {
		type: String,
		required: true,
	},
});

const loading = ref(true);
const lastExport = ref(null);
const nextExport = ref(null);

onMounted(async () => {
	const { data } = await useApi(`domains/${props.domainName}/el_export/`, {
		v3Agent: true,
		message: 'There was an issue getting your domain! Please try again later.',
	}).json();
	lastExport.value = data.value?.last_el_export;
	nextExport.value = data.value?.next_el_export;
	loading.value = false;
});
</script>
