<template>
	<tr>
		<td>
			<h4 class="text-md">
				<FAIcon fixed-width :icon="indicator" />
				Payment by card ending with {{ transaction.last4 }} on
				{{ dateFormatter({ date: transaction.date, showTime: true }) }}
			</h4>
		</td>
		<td>
			<MirusOnlyBorder inline>
				<a
					class="font-bold"
					:href="
						'//braintreegateway.com/merchants/3g737q2bszkrq2v7/transactions/' +
						transaction.id +
						'/'
					"
					target="_blank"
					rel="noopener noreferrer"
				>
					{{ transaction.id }}
				</a>
			</MirusOnlyBorder>
		</td>

		<td>
			<p :class="{ 'line-through': transactionFailed }" class="m-0 text-right text-base">
				{{ amountString }}
			</p>
		</td>
	</tr>
</template>

<script setup>
import { computed } from 'vue';

import { moneyFormatter, dateFormatter } from '@/utils';
import MirusOnlyBorder from '@/components/common/MirusOnlyBorder';

const props = defineProps({
	internal: { type: Boolean, required: true },
	transaction: { type: Object, required: true },
	receipt: { type: Object, required: true },
	reload: { type: Function, required: true },
});

const amountString = computed(() =>
	moneyFormatter.format(props.transaction.amount * (props.transaction.is_refund ? -1 : 1))
);

const paymentFailedStatuses = [
	'authorization_expired',
	'settlement_declined',
	'failed',
	'gateway_rejected',
	'processor_declined',
	'voided',
	'declined',
];
const paymentProcessingStatuses = [
	'submitted',
	'authorized',
	'authorizing',
	'settlement_pending',
	'settling',
	'submitted_for_settlement',
];

const transactionFailed = computed(() => paymentFailedStatuses.includes(props.transaction.status));
const transactionPending = computed(() =>
	paymentProcessingStatuses.includes(props.transaction.status)
);

const indicator = computed(() => {
	if (transactionFailed.value) {
		return 'ban';
	} else if (transactionPending.value) {
		return 'hourglass';
	} else {
		return 'badge-check';
	}
});
</script>

<style scoped module lang="scss"></style>
