<template>
	<div class="mt-8 flex flex-col gap-8">
		<div class="grid grid-cols-1 gap-8 lg:grid-cols-2">
			<GlobalTimeRangeSelector />

			<div class="rounded bg-white p-4 dark:bg-gray-600">
				<span class="text-lg font-medium leading-tight">Help</span>
				<div class="p-2">
					<GlossaryLink class="grow-0" />
					<BaseAlert dense type="info" class="mt-2 inline-flex">
						Looking for Campaign Stats? Click here to see more details
						<template #action>
							<BaseButton
								color="orange"
								class="w-full lg:w-fit"
								rounded="full"
								dense
								:to="{ name: 'campaigns' }"
							>
								Campaigns
							</BaseButton>
						</template>
					</BaseAlert>
				</div>
			</div>
		</div>
		<ProgressBar v-if="loading" class="w-full" />
		<template v-else>
			<div class="flex flex-col items-center rounded bg-white p-4 dark:bg-gray-600">
				<div v-if="statsHeaderItems" class="w-full">
					<h4 class="text-lg font-medium leading-none">Stats Overview</h4>
					<div class="mt-2 flex w-full flex-wrap justify-center gap-4">
						<InfoLink
							v-for="section in statsHeaderItems"
							:key="section.field"
							:keyword="section.glossary_keyword"
							:show-text="false"
							class="flex justify-center rounded bg-gray-100 p-2 dark:bg-gray-500"
						>
							<span class="inline-flex items-center gap-1">
								<span class="text-xl font-bold leading-none">
									{{ section.value }}
								</span>
								<span class="text-md font-normal">{{ section.label }}</span>
							</span>
						</InfoLink>
					</div>
				</div>
			</div>

			<div class="rounded bg-white dark:bg-gray-600">
				<InfoLink :show-text="false" class="ml-4 mt-4" keyword="page_views_over_time">
					<span class="text-lg font-medium leading-tight"> Page Views Over Time </span>
				</InfoLink>
				<ProgressBar v-if="!timeGraphOptions" class="w-full" />
				<Chart v-else :options="timeGraphOptions" class="w-full pr-8 pt-4" />
			</div>

			<div class="grid grid-cols-1 gap-8 lg:grid-cols-2">
				<div class="flex flex-col rounded bg-white p-4 dark:bg-gray-600">
					<InfoLink keyword="page_views_by_device_type" :show-text="false">
						<span class="text-lg font-medium leading-tight">
							Page Views by Device Type
						</span>
					</InfoLink>
					<Chart :options="deviceTypeGraphOptions" class="h-full min-h-[20rem]" />
				</div>
				<div class="rounded bg-white p-4 dark:bg-gray-600">
					<InfoLink size="lg" keyword="page_views_by_referrer" :show-text="false">
						<span class="text-lg font-medium leading-tight"
							>Page Views by Referrer</span
						>
					</InfoLink>
					<table class="mt-1 w-full divide-y divide-gray-300">
						<thead>
							<tr>
								<th
									class="px-3 py-1.5 text-left text-sm font-medium uppercase leading-tight tracking-wider text-gray-600"
								>
									Referrer
								</th>
								<th
									class="px-3 py-1.5 text-left text-sm font-medium uppercase leading-tight tracking-wider text-gray-600"
								>
									Page Views
								</th>
							</tr>
						</thead>
						<tbody class="divide-y divide-gray-200">
							<tr v-for="item in referrerTable" :key="item.label">
								<td
									class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
								>
									{{ item.label }}
								</td>
								<td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
									{{ item.hits.toLocaleString('en', { useGrouping: true }) }}
								</td>
							</tr>
							<tr v-if="!referrerTable.length">
								<td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
									None
								</td>
								<td />
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<template v-if="domainStats">
				<div class="grid h-min grid-cols-1 gap-8 lg:grid-cols-2">
					<div class="rounded bg-white p-4 dark:bg-gray-600">
						<InfoLink keyword="links_clicked_to_external_sites" :show-text="false">
							<span class="text-lg font-medium leading-tight"
								>Links Clicked to External Sites</span
							>
						</InfoLink>
						<table class="mt-1 w-full divide-y divide-gray-300">
							<thead>
								<tr>
									<th
										class="px-3 py-1.5 text-left text-sm font-medium uppercase leading-tight tracking-wider text-gray-600"
									>
										City
									</th>
									<th
										class="whitespace-nowrap px-3 py-1.5 text-left text-sm font-medium uppercase leading-tight tracking-wider text-gray-600"
									>
										Page Views
									</th>
								</tr>
							</thead>
							<tbody class="max-w-full divide-y divide-gray-200">
								<tr
									v-for="item in externalLinksTable"
									:key="item.label"
									class="max-w-full"
								>
									<td
										class="w-full max-w-[0] truncate py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
									>
										{{ item.label }}
									</td>
									<td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
										{{ item.hits }}
									</td>
								</tr>
								<tr v-if="!externalLinksTable.length">
									<td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
										None
									</td>
									<td />
								</tr>
							</tbody>
						</table>
					</div>
					<div class="rounded bg-white p-4 dark:bg-gray-600">
						<InfoLink keyword="bots_crawlers" :show-text="false">
							<span class="text-lg font-medium leading-tight">
								Bots and Crawlers Traffic (this data is not included in other stats)
							</span>
						</InfoLink>
						<table
							class="mt-1 w-full divide-y divide-gray-300 overflow-y-scroll rounded"
						>
							<thead>
								<tr>
									<th
										class="px-3 py-1.5 text-left text-sm font-medium uppercase leading-tight tracking-wider text-gray-600"
									>
										Crawlers
									</th>
									<th
										class="px-3 py-1.5 text-left text-sm font-medium uppercase leading-tight tracking-wider text-gray-600"
									>
										Page Views
									</th>
								</tr>
							</thead>
							<tbody class="divide-y divide-gray-200">
								<tr v-for="item in botTable" :key="item.label">
									<td
										class="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
									>
										{{ item.label }}
									</td>
									<td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
										{{ item.hits.toLocaleString('en', { useGrouping: true }) }}
									</td>
								</tr>
								<tr v-if="!botTable.length">
									<td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
										None
									</td>
									<td />
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</template>
			<ProgressBar v-else />
		</template>
	</div>
</template>
<script setup>
import { ref, computed, watch } from 'vue';
import { format, parseISO } from 'date-fns';
import { Chart } from 'highcharts-vue';
import { storeToRefs } from 'pinia';

import GlossaryLink from '@/components/glossary/GlossaryLink';
import InfoLink from '@/components/glossary/InfoLink';
import GlobalTimeRangeSelector from '@/components/common/GlobalTimeRangeSelector.vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseAlert from '@/components/ui/BaseAlert.vue';
import useDomainStore from '@/stores/agent/agent-domains';
import useTimeStore from '@/stores/time';

const statsRowHeaders = [
	{ title: 'Page Views', field: 'page_views', glossary_keyword: 'page_views' },
	{ title: 'Visits', field: 'visits', glossary_keyword: 'visits' },
	{ title: 'Quote Starts', field: 'quotestarts', glossary_keyword: 'quote_starts' },
	{ title: 'Contact Forms Sent', field: 'contactforms', glossary_keyword: 'contact_forms_sent' },
	{ title: 'Phone Clicks', field: 'phone_clicks', glossary_keyword: 'phone_clicks' },
	{ title: 'Text Clicks', field: 'sms_clicks', glossary_keyword: 'text_clicks' },
	{
		title: 'Map & Directions Clicks',
		field: 'map_clicks',
		glossary_keyword: 'map_directions_clicks',
	},
];

const props = defineProps({
	domain: {
		type: Object,
		required: true,
	},
});

const domainStore = useDomainStore();
const timeStore = useTimeStore();
const { domainM1Stats } = storeToRefs(domainStore);
const { start: statsStart, end: statsEnd } = storeToRefs(timeStore);

const domainStats = ref(null);
const loading = ref(true);

const statsHeaderItems = computed(() =>
	domainStats.value
		? statsRowHeaders
				.map(item => {
					return {
						value: domainStats.value.stats.stats[item.field],
						label: item.title,
						glossary_keyword: item.glossary_keyword,
					};
				})
				.filter(({ value }) => value !== null && value !== undefined)
		: null
);

const timeGraphOptions = computed(() => {
	let timeData = [];
	if (domainStats.value) {
		timeData = domainStats.value.stats.graph;
	} else {
		return null;
	}

	const timeSeries = {
		name: 'Page Views',
		data: timeData.map(item => {
			return {
				x: parseISO(item.date),
				y: item.page_views,
			};
		}),
		fillColor: 'rgb(254, 239, 214)',
		color: 'rgb(245,130,32)',
	};
	return {
		chart: {
			type: 'area',
			renderTo: 'time_graph',
			height: '350',
			style: {
				fontFamily: 'Roboto',
			},
		},
		title: { text: undefined },
		credits: false,
		tooltip: {
			formatter: function () {
				const xString = format(this.x, 'MMM do, yyyy');
				return (
					`<span style="font-size: 10px">${xString}</span><br/>` +
					`<span style="color:${this.point ? this.point.color : ''}">\u25CF</span> ${
						this.series ? this.series.name : ''
					}: <b>${this.point ? this.point.y : ''}</b><br/>`
				);
			},
		},
		xAxis: {
			type: 'datetime',
		},
		yAxis: {
			title: { text: 'Page Views' },
		},
		series: [timeSeries],
	};
});

const deviceTypeGraphOptions = computed(() => {
	const deviceTypeData = domainStats.value ? domainStats.value.stats.pies.device_type : null;

	if (deviceTypeData === null) {
		return null;
	}
	const deviceTypeSeries = Object.entries(deviceTypeData).map(([label, hits]) => {
		const readableNames = {
			console: 'Console',
			smarttv: 'Smart TV',
			tablet: 'Tablet',
			mobile: 'Mobile',
			desktop: 'Desktop',
			Unknown: 'Unknown',
		};
		return {
			name: readableNames[label],
			y: hits,
		};
	});

	return {
		chart: {
			type: 'pie',
			renderTo: 'device_type_graph',
			style: {
				fontFamily: 'Roboto',
			},
		},
		credits: false,
		title: { text: undefined },
		series: [
			{
				data: deviceTypeSeries,
				name: 'Visits',
			},
		],
		plotOptions: {
			pie: {
				colors: ['#fac177', '#f79b40', '#f58220', '#e66410', '#bf4b0f', '#973c15'],
			},
		},
	};
});

const referrerTable = computed(() => {
	return domainStats.value
		? Object.entries(domainStats.value.stats.pies.referrer)
				.map(([label, hits]) => {
					return { label, hits };
				})
				.sort((a, b) => b.hits - a.hits)
		: [{ label: 'None', hits: 'None' }];
});

const externalLinksTable = computed(() => {
	return domainStats.value
		? Object.entries(domainStats.value.stats.pies.external_links)
				.map(([label, hits]) => {
					return { label, hits };
				})
				.sort((a, b) => b.hits - a.hits)
		: [{ label: 'None', hits: 'None' }];
});

const botTable = computed(() => {
	return domainStats.value
		? Object.entries(domainStats.value.stats.pies.bot_browser)
				.map(([label, hits]) => {
					return { label, hits };
				})
				.sort((a, b) => b.hits - a.hits)
		: [];
});

if (domainStats.value === undefined && statsStart.value !== null && statsEnd.value !== null) {
	domainStore.getDomainM1Stats({
		domain_name: props.domain.domain_name,
		start: statsStart.value,
		end: statsEnd.value,
	});
}

watch(
	[statsStart, statsEnd],
	async ([start, end], prevState) => {
		if (start && end && (!prevState || start !== prevState[0] || end !== prevState[1])) {
			loading.value = true;
			await domainStore.getDomainM1Stats({
				domain_name: props.domain.domain_name,
				start: start,
				end: end,
			});
			domainStats.value = domainM1Stats.value[props.domain.domain_name];
			loading.value = false;
		}
	},
	{ immediate: true }
);
</script>
<style scoped></style>
