<template>
	<a
		v-if="Boolean($props.href)"
		v-bind="$attrs"
		:href="$props.href || $props.to"
		target="_blank"
		rel="nofollow noreferrer noopener"
		:class="[{ inheritColor }, colorClass]"
		class="baseLink"
	>
		<slot />

		<span class="sr-only">Opens a new window</span>
	</a>

	<RouterLink
		v-else
		v-slot="{ isActive, isExactActive, href: hrefFromRouterLink, navigate }"
		custom
		v-bind="{ to }"
	>
		<a
			:aria-current="isActive ? 'page' : null"
			:class="[
				{
					[exactActiveClass]: isExactActive,
					[activeClass]: isActive,
				},
				inheritColor ? 'inheritColor' : colorClass,
			]"
			:disabled="isExactActive"
			:href="hrefFromRouterLink"
			:target="openInNewTab ? '_blank' : '_self'"
			class="baseLink"
			@click="navigate"
			@keydown.esc="drawerIsOpen = false"
		>
			<slot v-bind="{ isActive, isExactActive }" />
		</a>
	</RouterLink>
</template>
<script>
import { defineComponent } from 'vue';

defineComponent({
	inheritAttrs: false,
});
</script>
<script setup>
/*
  Tweaked example from vue-router docs:
  https://router.vuejs.org/guide/advanced/extending-router-link.html
*/
import { computed } from 'vue';
import { RouterLink } from 'vue-router';

import { getColorClass } from '@/utils/styles';

const props = defineProps({
	...RouterLink.props,

	to: {
		...RouterLink.props.to,
		default: null,
		required: false,
		validator(value) {
			if (typeof value === 'string') {
				console.warn(
					`The "to" prop received the string ${value}, which should be passed to the href prop instead.`
				);
				return false;
			}
			return true;
		},
	},
	href: { type: String, default: null },

	color: { type: String, default: null },
	inheritColor: { type: Boolean, default: false },

	openInNewTab: { type: Boolean, default: false },
});

const colorClass = computed(() =>
	props.inheritColor ? '' : getColorClass(props.color, 'mirusOrange')
);
</script>
