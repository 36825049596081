<template>
	<div
		class="grid w-full grid-cols-1 gap-2 rounded-3xl border-2 border-gray-300 bg-gray-100 p-4 shadow dark:border-gray-900 dark:bg-gray-700 dark:text-gray-100 dark:shadow-gray-700 lg:grid-cols-[min-content,_1fr,_max-content] lg:gap-4"
	>
		<div class="ml-1.5 mt-1.5 flex">
			<div class="relative inline-block">
				<div
					:class="[
						`text-${productType.badgeColor}-800`,
						`border-${productType.badgeColor}-800`,
						`bg-${productType.badgeColor}-100 dark:bg-${productType.badgeColor}-800`,
					]"
					class="inline-flex h-14 w-14 items-center justify-center rounded-md border-2 p-1 text-2xl font-semibold"
				>
					<span>{{ productType.initials }}</span>
				</div>

				<FAIcon
					class="absolute left-0 top-0 block h-3 w-3 -translate-x-2 -translate-y-2 rounded-full bg-green-50 p-1 text-xs text-green-600 ring-2 ring-green-600"
					icon="lock"
				/>
			</div>
			<h4 class="ml-2 flex w-32 flex-col md:w-auto">
				<span class="!m-0 w-full truncate pb-1 text-xl font-medium leading-none">
					{{ domain.domain_name }}
				</span>
				<span class="!m-0 truncate p-0 leading-none">
					<div>
						<span class="mr-1 text-xs uppercase tracking-widest">View:</span>
						<a :href="`https://${domain.domain_name}/`" target="_blank">
							<span class="truncate text-sm">https://{{ domain.domain_name }}/</span>
							<FAIcon
								icon="fa-solid fa-arrow-up-right-from-square"
								class="ml-1.5 translate-y-0.5"
								size="sm"
							/>
						</a>
					</div>
					<span class="mr-1 text-xs uppercase tracking-widest">
						{{ lastEvent.type }}:
					</span>
					<span class="fmr-1 text-xs font-bold uppercase tracking-widest">{{
						lastEvent.date_formatted ?? 'Never'
					}}</span>
					<br />
					<span
						v-if="['m1', 'm2'].includes(domain.product_type)"
						class="mr-1 text-xs uppercase tracking-widest"
						>Last Edited:
					</span>
					<span
						v-if="['m1', 'm2'].includes(domain.product_type)"
						class="fmr-1 text-xs font-bold uppercase tracking-widest"
					>
						{{ lastEdited.date_formatted ?? 'Never' }}
					</span>
				</span>
			</h4>
		</div>
		<div
			v-if="canSeeAdminFunction"
			class="col-start-1 flex grow-0 flex-col gap-1 rounded-md border-2 border-dotted border-orange-800 bg-orange-100 p-1 leading-none dark:bg-gray-600 lg:col-start-3"
		>
			<div class="mx-1 mt-1 text-xs uppercase tracking-wider text-gray-900">
				Admin &mdash; SF & Mirus only
			</div>
			<div class="flex flex-wrap items-end gap-1 p-1">
				<BaseButton v-if="micrositeUrl" :to="micrositeUrl" color="primary" text>
					View SF Microsite
				</BaseButton>
				<BaseButton color="primary" text @click="openPanel('el')"> EL Export </BaseButton>
				<BaseButton color="primary" text @click="openPanel('crosswalkButton')">
					Refresh Microsite Data
				</BaseButton>
			</div>
		</div>

		<div class="col-start-1 flex items-center gap-x-2">
			<BaseButton
				v-if="['m1', 'm2'].includes(domain.product_type)"
				:to="{
					name: 'editorIndex',
					params: { domainName: domain.domain_name, feature: 'home' },
				}"
				color="orange"
			>
				<FAIcon class="mr-1" icon="fa-solid fa-wand-magic-sparkles" />
				Edit Website <span class="sr-only">{{ domain.domain_name }}</span>
			</BaseButton>

			<DomainTypeChange
				v-if="domain.product_type !== 'm2'"
				:button-only="true"
				:disabled="agent.is_agent_intern"
				:options="{
					service: domain.domain_name,
					original_product_type: domain.product_type,
					future_product_type: 'm2',
					email_count: filteredEmails.length,
				}"
				action-color="success"
				action-icon="arrow-up"
				action-text="Upgrade to M2"
				@confirm="domainStore.setProductType(agent.associate_id, domain.domain_name, 'm2')"
			/>
		</div>

		<div class="col-start-1 flex flex-wrap gap-2 lg:col-start-3 lg:justify-end">
			<BaseButton
				v-if="!['parked', 'old_parked'].includes(domain.product_type)"
				:color="panel === 'stats' ? 'dark-gray' : 'gray'"
				class="pr-2"
				@click="openPanel('stats')"
			>
				<template #prepend>
					<FAIcon class="mr-1" icon="fa-solid fa-chart-line" />
				</template>
				Stats
				<template #append>
					<FAIcon
						:icon="panel === 'stats' ? 'chevron-up' : 'chevron-down'"
						class="aspect-square rounded-full bg-white p-1 text-gray-600"
					/>
				</template>
			</BaseButton>
			<BaseButton
				:color="panel === 'email' ? 'dark-gray' : 'gray'"
				class="pr-2"
				@click="openPanel('email')"
			>
				<template #prepend>
					<FAIcon icon="fa-solid fa-envelope" />
				</template>
				Emails

				<template #append>
					<FAIcon
						:icon="panel === 'email' ? 'chevron-up' : 'chevron-down'"
						class="aspect-square rounded-full bg-white p-1 text-gray-600"
					/>
				</template>
			</BaseButton>
			<BaseButton
				:color="panel === 'settings' ? 'dark-gray' : 'gray'"
				class="pr-2"
				@click="openPanel('settings')"
			>
				<template #prepend>
					<FAIcon class="mr-1.5" icon="fa-solid fa-cog" />
				</template>
				Settings
				<template #append>
					<FAIcon
						:icon="panel === 'settings' ? 'chevron-up' : 'chevron-down'"
						class="aspect-square rounded-full bg-white p-1 text-gray-600"
					/>
				</template>
			</BaseButton>
		</div>

		<div v-if="panel" class="col-span-full mt-4">
			<MxStatsSection v-if="useMxStats && panel === 'stats'" :domain="domain" />
			<BasicStatsSection
				v-if="useBasicStats && panel === 'stats'"
				:domain="domain"
				class="mt-4"
			/>

			<EmailList v-if="panel === 'email'" :domain="domain" class="mt-4" />

			<DomainSettings v-if="panel === 'settings'" :domain="domain" />

			<div v-if="panel === 'el'" class="mt-4">
				<ELExportViewer :domain-name="domain.domain_name" />
				<BaseButton @click="openPanel('el')">Close</BaseButton>
			</div>

			<div v-if="panel === 'crosswalkButton'" class="mt-4">
				<RefreshMicrositeDataViewer :domain-name="domain.domain_name" />
				<BaseButton @click="openPanel('crossWalkButton')">Close</BaseButton>
			</div>
		</div>
	</div>
</template>
<script setup>
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { parseISO, format } from 'date-fns';

import { productTypes } from '@/constants';

import useUserStore from '@/stores/user';
import useDomainStore from '@/stores/agent/agent-domains';
import useEmailStore from '@/stores/agent/agent-emails';
import useAgentStore from '@/stores/agent/agent';

import BaseButton from '@/components/ui/BaseButton';

import DomainTypeChange from '@/components/domains/DomainTypeChange';
import MxStatsSection from '@/components/domains/MxStatsSection';
import BasicStatsSection from '@/components/domains/BasicStatsSection';
import DomainSettings from '@/components/domains/DomainSettings';
import ELExportViewer from '@/components/domains/ELExportViewer';
import RefreshMicrositeDataViewer from '@/components/domains/RefreshMicrositeDataViewer';
import EmailList from '@/components/emails/EmailList';

const props = defineProps({
	domain: { type: Object, required: true },
});

const agentStore = useAgentStore();
const userStore = useUserStore();
const domainStore = useDomainStore();
const emailStore = useEmailStore();

const { agent } = storeToRefs(agentStore);
const { emails } = storeToRefs(emailStore);
const { user } = storeToRefs(userStore);

const lastEvent = computed(() => {
	return {
		date_formatted: props.domain.last_service_event_date
			? format(parseISO(props.domain.last_service_event_date), 'LLL do yyyy')
			: null,
		type: props.domain.last_service_event_type,
	};
});

const lastEdited = computed(() => {
	return {
		date_formatted: props.domain.last_dco_date
			? format(parseISO(props.domain.last_dco_date), 'LLL do yyyy')
			: null,
	};
});

const filteredEmails = computed(
	() => emails.value?.filter(item => item.domain_name === props.domain.domain_name) ?? []
);

const useMxStats = computed(() => ['m2', 'm1'].includes(props.domain.product_type));
const useBasicStats = computed(() =>
	['sf_microsite', 'parked'].includes(props.domain.product_type)
);

const micrositeUrl = computed(
	() =>
		`https://www.statefarm.com/agent/us/xx/_/sf-${
			agent?.value?.associate_id?.toLowerCase() ?? ''
		}`
);
const canSeeAdminFunction = computed(() => ['mirus', 'corp'].includes(user.value?.user_type));

const panel = ref(false);
function openPanel(newPanel) {
	if (panel.value === newPanel) {
		panel.value = false;
	} else {
		panel.value = newPanel;
	}
}

const productType = computed(() => productTypes[props.domain.product_type]);
</script>
<style>
/*
	TW dynamic styles
	text-red-800 border-red-800 bg-red-100
	text-orange-800 border-orange-800 bg-orange-100
	text-green-800 border-green-800 bg-green-100
	text-blue-800 border-blue-800 bg-blue-100
	text-indigo-800 border-indigo-800 bg-indigo-100
*/
</style>
