<template>
	<SortableTable
		:headers="headers"
		:items="items"
		initial-sort-by="page_views"
		item-unique-key="key"
		title="Detailed Stats"
		description="Please contact your third party vendor for questions regarding your campaign code statistics. Other than the data displayed, Mirus does not have any specific information about your marketing campaign activities."
		:sort-desc="true"
		show-pagination
		show-title
	>
		<template #header.cmpid>
			<span>Campaign IDs</span>
			<InfoLink
				keyword="campaign_ids"
				icon-size="lg"
				:show-text="false"
				tooltip-position="bottom"
			/>
		</template>

		<template #header.page_views>
			<span>Page Views</span>
			<InfoLink
				keyword="page_views"
				icon-size="lg"
				:show-text="false"
				tooltip-position="bottom"
			/>
		</template>
		<template #header.quotestarts>
			<span>Quote starts</span>
			<InfoLink
				keyword="quote_starts"
				icon-size="lg"
				:show-text="false"
				tooltip-position="bottom"
			/>
		</template>
		<template #header.phone_clicks>
			<span>Phone Clicks</span>
			<InfoLink
				keyword="phone_clicks"
				icon-size="lg"
				:show-text="false"
				tooltip-position="bottom"
			/>
		</template>
		<template #header.sms_clicks>
			<span>SMS Clicks</span>
			<InfoLink
				keyword="sms_clicks"
				icon-size="lg"
				:show-text="false"
				tooltip-position="bottom"
			/>
		</template>
		<template #item.traffic_source="{ traffic_source, icon }">
			<FAIcon v-if="traffic_source" small color="secondary" :icon="icon" />
			{{ traffic_source }}
		</template>

		<template #toolbar>
			<BaseButton
				v-if="start && end"
				:href="`/api/v3/agents/${
					agent.associate_id
				}/campaigns/download_report/?start=${format(start, 'yyyy-MM-dd')}&end=${format(
					end,
					'yyyy-MM-dd'
				)}`"
				color="orange"
				label="Download Campaign Stats Report"
				target="_blank"
				rel="noopener noreferrer"
			>
				<i class="fa fa-cloud-download glowing_icon" />
				Download Stats
			</BaseButton>
		</template>
	</SortableTable>
</template>

<script setup>
import { computed } from 'vue';
import { format } from 'date-fns';
import { storeToRefs } from 'pinia';

import InfoLink from '@/components/glossary/InfoLink';
import BaseButton from '@/components/ui/BaseButton.vue';
import SortableTable from '@/components/ui/SortableTable.vue';
import useAgentStore from '@/stores/agent/agent';
import useTimeStore from '@/stores/time';

const headers = [
	{ text: 'Domain', value: 'domain_name', sortable: true },
	{ text: 'Landing Page', value: 'path', sortable: true, truncate: true },
	{ text: 'Traffic Source', value: 'traffic_source', sortable: true },
	{ text: 'Campaign IDs', value: 'cmpid', sortable: true, truncate: true },
	{ text: 'Page Views', value: 'page_views', sortable: true },
	{ text: 'Quote Starts', value: 'quotestarts', sortable: true },
	{ text: 'Phone Clicks', value: 'phone_clicks', sortable: true },
	{ text: 'SMS Clicks', value: 'sms_clicks', sortable: true },
];

const traffic_source_icons = {
	GD: 'fa-brands fa-google',
	GM: 'fa-brands fa-google',
	BD: 'fa-brands fa-microsoft',
	BM: 'fa-brands fa-microsoft',
	FD: 'fa-brands fa-facebook',
	FM: 'fa-brands fa-facebook',
	FP: 'fa-brands fa-facebook',
	TD: 'fa-brands fa-x-twitter',
	TM: 'fa-brands fa-x-twitter',
	LD: 'fa-brands fa-linkedin',
	LM: 'fa-brands fa-linkedin',
	DD: 'fa-desktop',
	MD: 'fa-mobile',
	IY: 'fa-newspaper',
	MR: 'fa-circle-m',
	U0: 'fa-block-question',
	U1: 'fa-block-question',
	U2: 'fa-block-question',
	U3: 'fa-block-question',
	U4: 'fa-block-question',
	U5: 'fa-block-question',
	U6: 'fa-block-question',
	U7: 'fa-block-question',
	U8: 'fa-block-question',
	U9: 'fa-block-question',
	YD: 'fa-brands fa-youtube',
	YM: 'fa-brands fa-youtube',
};

const props = defineProps({
	row: { type: Array, required: true },
});

const agentStore = useAgentStore();
const timeStore = useTimeStore();
const { agent } = storeToRefs(agentStore);
const { start, end } = storeToRefs(timeStore);

const items = computed(() => {
	return (props?.row ?? []).map(item => {
		const modified = { ...item };
		const { cmpid } = item;

		if (cmpid) {
			modified.icon = 'fa-block-question';
			if (!cmpid.includes('_BLM_') && !cmpid.includes('DFA:')) {
				modified.icon = traffic_source_icons[cmpid.substr(0, 2)] || 'fa-block-question';
			}
		} else {
			modified.cmpid = 'No Campaign ID';
			modified.traffic_source = 'No Traffic Source';
			modified.icon = 'fa-square-xmark';
		}
		modified.key = `${modified.domain_name}_${modified.path}_${modified.traffic_source}_${modified.cmpid}`;

		return modified;
	});
});
</script>
