<template>
	<div>
		<ProgressBar
			v-if="!agent || (fetchingEmails && !hasItems)"
			:value="emailLoadProgress"
			:max="100"
			text="Loading redirects..."
		/>

		<template v-else>
			<div class="flex items-start justify-between gap-2">
				<div ref="textContent" class="w-2/3">
					<h4 v-if="hasItems" class="text-2xl">Email redirects for this domain</h4>
					<template v-else>
						<p class="mb-4">
							There are no email redirects for
							<a :href="domain.domain_name">{{ domain.domain_name }}</a>
							.
							<span>
								If you want to add a few (and you
								<strong>know</strong>
								you want to...) you should take advantage of our
								<span v-if="domain.product_type !== 'parked'">free </span>email
								redirects feature.
							</span>
						</p>
						<BaseButton
							v-if="!fetchingEmails"
							outline
							:disabled="autopopulating"
							color="primary"
							@click="autoPopulate"
						>
							automatically set up your redirects
						</BaseButton>
					</template>
					<p v-if="domain.product_type === 'parked'" class="mb-0 mt-2">
						Because {{ domain.domain_name }} is a Secure Parked Domain, Email Redirects
						cost $1/mo. each.
					</p>
				</div>

				<BaseButton color="primary" @click="focusNewEmail">
					Add redirect <FAIcon icon="plus" />
				</BaseButton>
			</div>
			<ul class="mt-4 pl-0">
				<li
					v-for="email in emailListForDomain"
					:key="email.pk"
					class="px-1 py-0.5 odd:bg-white even:bg-gray-100 dark:odd:bg-gray-600 dark:even:bg-gray-700"
				>
					<RedirectEditor
						v-if="editingList[email.pk]"
						:email-list="emailListForDomain"
						:loading="loadingList[email.pk]"
						:disabled="disableRedirects"
						v-bind="email"
						class="mt-2 first:mt-0"
						:destination-options="destinationOptions"
						@cancel="updateList({ pk: email.pk, list: 'editing' })"
						@change="handleEmailChange"
					/>
					<DeleteRedirect
						v-else-if="deletingList[email.pk]"
						:loading="loadingList[email.pk]"
						v-bind="email"
						class="mt-2 first:mt-0"
						@cancel="updateList({ pk: email.pk, list: 'deleting' })"
						@confirmDelete="handleDelete(email)"
					/>
					<RedirectListItem
						v-else
						:loading="loadingList[email.pk]"
						v-bind="email"
						class="mt-2 first:mt-0"
						:disabled="disableRedirects"
						@deleteEmail="updateList({ pk: email.pk, list: 'deleting' })"
						@editEmail="updateList({ pk: email.pk, list: 'editing' })"
					/>
				</li>
				<li
					v-for="n in processingEmailsForDomain"
					:key="n.redirect"
					class="odd:bg-white even:bg-gray-100 dark:odd:bg-gray-600 dark:even:bg-gray-700"
				>
					<RedirectListItem
						v-bind="n"
						:email-list="emailListForDomain"
						:destination-options="destinationOptions"
						loading
						disabled
					/>
				</li>
			</ul>
			<template v-if="agent">
				<hr class="mt-4" />
				<RedirectEditor
					ref="newEmail"
					v-bind="newEmailData"
					:disabled="disableRedirects"
					:email-list="emailListForDomain"
					:domain_name="domain.domain_name"
					:destination-options="destinationOptions"
					is-new
					class="mt-2"
					@change="handleEmailChange"
				/>
			</template>
			<BaseDialog :value="dialogOpen" max-width="500" @close="resetNewEmails">
				<template #header>
					<h4 class="text-xl font-bold">
						Add New Email{{
							newEmailListData && newEmailListData.length > 1 ? 's' : ''
						}}
					</h4>
				</template>

				<EmailPaymentConfirmation
					:email="newEmailData"
					:emails="newEmailListData"
					:existing-emails="emailListForDomain"
				/>

				<template #actions>
					<BaseButton text @click="resetNewEmails">Close</BaseButton>
					<BaseButton color="primary" outline @click="saveNewEmails">
						Purchase
					</BaseButton>
				</template>
			</BaseDialog>
		</template>
	</div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';

import EmailPaymentConfirmation from '@/components/emails/EmailPaymentConfirmation';
import DeleteRedirect from '@/components/emails/DeleteRedirect';
import RedirectEditor from '@/components/emails/RedirectEditor';
import RedirectListItem from '@/components/emails/RedirectListItem';

import ProgressBar from '@/components/ui/ProgressBar';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseDialog from '@/components/ui/BaseDialog';

import useAgentStore from '@/stores/agent/agent';
import useEmailStore from '@/stores/agent/agent-emails';

const props = defineProps({
	domain: { type: Object, required: true },
});

const emailStore = useEmailStore();
const agentStore = useAgentStore();
const autopopulating = ref(false);
const newEmail = ref(null);
const newEmailData = ref({ redirect: '', destination_address: `` });
const newEmailListData = ref(null);
const loadingList = ref({});
const editingList = ref({});
const deletingList = ref({});

function filterByDomain(email) {
	return email.domain_name === props.domain?.domain_name;
}

const { agent } = storeToRefs(agentStore);
const { fetchingEmails, emailLoadProgress, emails, processingEmails } = storeToRefs(emailStore);

const emailListForDomain = computed(() => emails.value?.filter(filterByDomain) ?? []);
const processingEmailsForDomain = computed(
	() => processingEmails.value?.filter(filterByDomain) ?? []
);
const disableRedirects = computed(() =>
	Boolean(fetchingEmails.value || processingEmailsForDomain.value?.length > 0)
);

const destinationOptions = computed(() => {
	const base = agent.value?.staff?.map(staff => {
		return staff.email_address;
	});
	base?.unshift(agent.value?.email_address);
	return base;
});
const isParked = computed(() => {
	return ['parked', 'old_parked'].includes(props.domain?.product_type);
});
const dialogOpen = computed(() => {
	return Boolean(
		['parked', 'old_parked'].includes(props.domain?.product_type) &&
			!(processingEmailsForDomain.value?.length > 0) &&
			((newEmailData.value?.redirect && newEmailData.value?.destination_address) ||
				newEmailListData.value)
	);
});

const hasItems = computed(() => {
	return emailListForDomain.value?.length > 0;
});

function resetNewEmails() {
	newEmailData.value = { redirect: ``, destination_address: `` };
	newEmailListData.value = null;
	loadingList.value = {};
	editingList.value = {};
	deletingList.value = {};
	autopopulating.value = false;
}

async function autoPopulate() {
	autopopulating.value = true;
	//store names to check for duplicates
	const agentName = (agent.value?.first_name ?? '').toLowerCase().split(' ').join('');

	const newRedirects = new Map();
	newRedirects.set(agentName, {
		redirect_address: `${agentName}@${props.domain.domain_name}`,
		real_address: agent.value.email_address,
	});

	function getUniqueRedirect(staff) {
		let { first_name, last_name } = staff;
		first_name = first_name.split(' ').join('').toLowerCase();
		last_name = last_name.split(' ').join('').toLowerCase();

		//check for first_name as a key, and use it if possible
		//check for first_name + last name as a key, and use it if possible
		if (newRedirects.has(first_name)) {
			const fullName = `${first_name}${last_name}`;
			if (newRedirects.has(fullName)) {
				let suffix = 1;
				let adding = true;
				while (adding) {
					const maybeThisOne = `${first_name}${last_name}${suffix}`;
					if (newRedirects.has(maybeThisOne)) {
						suffix += 1;
					} else {
						adding = false;
						return maybeThisOne;
					}
				}
			} else {
				return fullName;
			}
		} else {
			return first_name;
		}
	}

	agent.value?.staff.forEach(staff => {
		const name = getUniqueRedirect(staff);

		newRedirects.set(name, {
			redirect_address: `${name}@${props.domain?.domain_name}`,
			real_address: staff.email_address,
		});
	});

	newEmailListData.value = Array.from(newRedirects.values());
	if (!['parked', 'old_parked'].includes(props.domain?.product_type)) {
		await saveNewEmails();
	}
}

async function saveEmail({ redirect_address, real_address, pk }) {
	try {
		await emailStore.addNewEmail({
			real_address,
			redirect_address,
			domain_name: props.domain.domain_name,
		});
		resetNewEmails();
	} catch (error) {
		console.error(error);
	} finally {
		updateList({ pk, list: 'editing' });
		updateList({ pk, list: 'loading' });
	}
}

async function saveNewEmails() {
	let redirectAddresses = newEmailListData.value;

	if (newEmailData.value?.redirect && newEmailData.value?.destination_address) {
		redirectAddresses = [
			{
				redirect_address: `${newEmailData.value.redirect}@${props.domain.domain_name}`,
				real_address: newEmailData.value.destination_address,
			},
		];
	}
	if (redirectAddresses) {
		try {
			const promises = [];
			redirectAddresses.forEach(redirect => {
				const { redirect_address, real_address } = redirect;
				promises.push(
					emailStore.addNewEmail({
						domain_name: props.domain.domain_name,
						redirect_address,
						real_address,
					})
				);
			});
			await Promise.all(promises);
			resetNewEmails();
		} catch (error) {
			console.error(error);
		}
	}
}

async function handleDelete(email) {
	updateList({ pk: email.pk, list: 'loading' });
	await emailStore.deleteEmail({
		domain: email.domain_name,
		redirect: email.redirect,
	});
	resetNewEmails();
}

async function handleEmailChange({ destination_address, redirect, original, pk }) {
	if (isParked.value && !pk) {
		newEmailData.value.redirect = redirect;
		newEmailData.value.destination_address = destination_address;
	} else {
		//existing emails have pk, new ones do not
		if (pk) {
			updateList({ pk, list: 'loading' });

			await emailStore.saveEmailEdits({
				domain: props.domain.domain_name,
				redirect: original,
				redirect_address: `${redirect}@${props.domain.domain_name}`,
				real_address: destination_address,
			});
			resetNewEmails();
		} else {
			await saveEmail({
				redirect_address: `${redirect}@${props.domain.domain_name}`,
				real_address: destination_address,
			});
		}
	}
}

function updateList({ pk, list = 'editing' }) {
	switch (list) {
		case 'deleting':
			deletingList.value = {
				...deletingList.value,
				[pk]: deletingList.value[pk] ? false : true,
			};
			break;
		case 'loading':
			loadingList.value = {
				...loadingList.value,
				[pk]: loadingList.value[pk] ? false : true,
			};

			break;
		case 'editing':
		default:
			editingList.value = {
				...editingList.value,
				[pk]: editingList.value[pk] ? false : true,
			};

			break;
	}
}

function focusNewEmail() {
	newEmail.value?.$refs.redirectInput.focus();
}

onMounted(async () => {
	await emailStore.ensureEmails();
});

defineExpose({
	hasItems,
	newEmailData,
	newEmailListData,
	loadingList,
	deletingList,
	editingList,
	autopopulating,
	disableRedirects,
	resetNewEmails,
	emailList: emailListForDomain,
});
</script>
