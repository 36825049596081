<template>
	<ProgressBar v-if="!billingData" />
	<div v-else>
		<p>
			You are transferring in <b>{{ domainName }}</b> as a
			<b>{{ selectedProduct.titleLong }}</b>
			, for
			<b>${{ Number(selectedProduct.price).toFixed(2) }}/mo.</b>
		</p>

		<div v-if="billingData">
			<p>
				You will be charged a pro-rated amount of
				<b>${{ Number(billingData.prorate).toFixed(2) }}</b>
				for the remaining
				<b>{{ billingData.days_remaining }} days </b>
				of this billing period
			</p>

			<p>
				Your monthly rate will be increased by
				<b> ${{ Number(selectedProduct.price).toFixed(2) }} </b>
				as of your next billing day,
				<b>{{ nextBillingDay }}</b>
			</p>

			<template v-if="productType === 'm2'">
				<M2DiscountList />
			</template>
		</div>

		<div class="flex justify-end gap-4 pt-4">
			<BaseButton
				:disabled="!billingData || loading"
				color="primary"
				outline
				@click="$emit('confirm')"
			>
				Begin Transfer
			</BaseButton>
			<BaseButton :disabled="loading" color="gray" @click="$emit('cancel')">
				Do not Transfer
			</BaseButton>
		</div>
	</div>
</template>

<script setup>
import { computed, watch, inject } from 'vue';
import { storeToRefs } from 'pinia';
import { format } from 'date-fns';

import { productTypes } from '@/constants';

import useBillingStore from '@/stores/agent/agent-billing';

import BaseButton from '@/components/ui/BaseButton.vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';

import M2DiscountList from '@/components/domains/purchase/M2DiscountList.vue';

const billingStore = useBillingStore();
const { billingData } = storeToRefs(billingStore);

defineProps({
	loading: { type: Boolean, required: true },
});

const { domainName } = inject('domainName');
const { productType } = inject('productType');

const selectedProduct = computed(() => productTypes[productType.value]);
const nextBillingDay = computed(() =>
	format(new Date(billingData.value.next_billing_day), 'MMMM d, yyyy')
);

watch(
	() => selectedProduct.value,
	() => billingStore.getBillingAmount(selectedProduct.value.price),
	{ immediate: true }
);
</script>

<style scoped></style>
