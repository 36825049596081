<template>
	<NotificationBase v-bind="$props">
		<template #title>
			Congratulations!
			<p class="mb-0">You have successfully upgraded to the Quote Landing Page system</p>
		</template>
		<div class="flex flex-wrap lg:flex-nowrap">
			<div class="w-full pr-12 lg:w-1/2">
				<p>
					All of your services are now running under the new Quote Landing Page system.
					Right now we&#39;re building out the controls to let you manage your services
					(such as unlimited email addresses), and you&#39;ll automatically have access as
					soon as those features are available.
				</p>
				<p>
					<a :href="`${linkPrefix}qlp_info/`" class="green_text">
						How do Quote Landing Pages work?
					</a>
					And how do I
					<a :href="`${linkPrefix}qlp_setup/`" class="green_text">set one up</a>?
				</p>
				<p>
					In the mean time if you need anything, just
					<a href="tel:18778283131" class="green_text">call</a> or
					<a href="mailto:support@mysfdomain.com" :class="green_text">email</a> us.
				</p>
			</div>
			<div>
				<h4>
					Learn how to
					<a :href="`${linkPrefix}qlp_setup/`" class="text-green-500">
						customize yours
					</a>
				</h4>
				<ul>
					<li>
						<span class="text-gray-600">
							https://www.{{ primaryDomain }}.{{ primaryTld }}
						</span>
						/quote/
					</li>
					<li>
						<span class="text-gray-600">
							https://www.{{ primaryDomain }}.{{ primaryTld }}
						</span>
						/quote/auto/
					</li>
					<li>
						<span class="text-gray-600">
							https://www.{{ primaryDomain }}.{{ primaryTld }}
						</span>
						/quote/home/
					</li>
					<li>
						<span class="text-gray-600">
							https://www.{{ primaryDomain }}.{{ primaryTld }}
						</span>
						/quote/renters/
					</li>
					<li>
						<span class="text-gray-600">
							https://www.{{ primaryDomain }}.{{ primaryTld }}
						</span>
						/quote/condo/
					</li>
				</ul>
			</div>
		</div>
	</NotificationBase>
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

import NotificationBase from '@/components/notifications/NotificationBase';
import useAgentStore from '@/stores/agent/agent';

const props = defineProps({
	body: { type: String, required: true },
	created: { type: String, required: true },
	dismissed: { type: Boolean, required: true },
	pk: { type: Number, required: true },
	urgency: { type: String, required: true },
});

const agentStore = useAgentStore();
const { agent } = storeToRefs(agentStore);

const domain = computed(() => JSON.parse(props.body).domain_name);
const linkPrefix = computed(() => `/agents/${agent.value?.associate_id}/`);
const primaryDomain = computed(() => domain.value?.substring(0, domain.value?.lastIndexOf('.')));
const primaryTld = computed(() =>
	domain.value?.substring(domain.value?.lastIndexOf('.') + 1, domain.value?.length)
);
</script>
