<template>
	<div class="m-4">
		<div class="grid grid-cols-1 gap-6 md:grid-cols-2">
			<div>
				<h3 class="text-xl">Your customers have gone mobile, and they want to talk</h3>
				<p>
					Over 90% of customers now begin their search on one device and complete it on
					another - most commonly smartphones.
				</p>
				<p>
					Some of your best online leads are calling your office. Find out which ad
					dollars are working. Get higher conversion rates and make more effective use of
					your marketing budget.
				</p>
				<p>
					Let us help you connect the dots from
					<strong>Campaign</strong>
					to
					<strong>Call</strong>
					to
					<strong>Revenue</strong>
				</p>
			</div>

			<figure>
				<img alt="" :src="callGraphic" />
			</figure>

			<div class="col-span-2">
				<h3 class="text-xl">The Complete Call Platform</h3>

				<ul class="space-y-2 pl-0 pt-3">
					<li v-for="{ icon, title, text } in sellingPoints" :key="title" class="flex">
						<FAIcon :icon="icon" class="basis-1/12 pr-2 pt-2" />

						<div class="flex basis-11/12 flex-col">
							<strong>{{ title }}</strong>
							<span class="text-sm">{{ text }}</span>
						</div>
					</li>
				</ul>
			</div>

			<div class="flex-col gap-2 pr-4">
				<h3 class="text-xl">Call Intelligence Platform</h3>
				<p>
					Know which campaigns are driving phone calls in our simple and intuitive
					dashboard. Understand which campaigns are effective, visualize trends, and dig
					into individual calls for unprecedented detail.
				</p>

				<p>
					The Call Intelligence Platform requests the caller ID data on each call, so
					you'll have a record in your dashboard and call history of who called you, not
					just which phone numbers.
				</p>
			</div>

			<div>
				<h3 class="text-xl">How does it help your agency?</h3>
				<p>
					Call tracking can be a game changer for agents who want to get to the root of
					who their customers are and what marketing channels drive them to call. Agencies
					that use call tracking gain in-depth insight into caller demographics and
					behavior. This gives those agencies an enormous edge over their competition, and
					can ultimately help them capture more leads and win clients.
				</p>
			</div>
		</div>

		<hr aria-orientation="horizontal" class="mb-3" />

		<h3 class="text-xl">Pricing</h3>
		<p>
			Call Tracking costs $26 a month for 3 local, unique and trackable lines and 200 minutes.
			Extra minutes are $0.07/min.
		</p>

		<BaseDialog max-width="600">
			<template #activator="{ openDialog }">
				<BaseButton
					:loading="loadingBillingData"
					color="primary"
					:disabled="loadingBillingData"
					@click="
						getBilling();
						openDialog();
					"
				>
					Sign Me Up
				</BaseButton>
			</template>
			<template #header><h3>Review Billing Details</h3></template>
			<ProgressBar v-if="loadingBillingData" />
			<div v-else-if="billingData">
				<div v-if="processing">
					<p>
						<i class="fa fa-spinner fa-spin" />
						Processing your order!
					</p>
				</div>

				<div v-else>
					<p>
						We will charge you a pro-rated amount of ${{
							Number(billingData.prorate).toFixed(2)
						}}
						today for the {{ billingData.days_remaining }} day{{
							billingData.days_remaining > 1 ? 's' : ''
						}}
						remaining on your current monthly bill.
					</p>
					<p>
						Starting on {{ nextBillingDay }}, we will charge $26 monthly for the Call
						Tracking service.
					</p>
				</div>
			</div>
			<template #actions="{ closeDialog }">
				<BaseButton
					color="gray"
					:disabled="processing || !billingData"
					@click="
						cancel();
						closeDialog();
					"
				>
					Cancel
				</BaseButton>
				<BaseButton
					color="primary"
					:disabled="processing || !billingData"
					@click="
						activate();
						closeDialog();
					"
				>
					Confirm
				</BaseButton>
			</template>
		</BaseDialog>

		<div class="my-4 rounded-lg">
			<p class="mb-2 text-xl font-bold leading-5">
				Call or e-mail us about the power of call tracking for your office
			</p>
			<div class="flex w-full max-w-md space-x-4">
				<a
					href="tel:18778283131"
					class="flex w-full items-center justify-center space-x-2 rounded-lg bg-gray-700 p-4 text-lg font-bold !text-white hover:bg-gray-900"
				>
					<FAIcon icon="fa-phone" />
					<span>1-877-828-3131</span>
				</a>
				<a
					href="mailto:support@mysfdomain.com"
					class="flex w-full items-center justify-center space-x-2 rounded-lg bg-gray-700 p-4 text-lg font-bold !text-white hover:bg-gray-900"
				>
					<FAIcon icon="fa-envelope" />
					<span>Email</span>
				</a>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue';
import { format } from 'date-fns';

import { useApi } from '@/composables/useApi';

import useCalltrackingStore from '@/stores/agent/agent-calltracking';

import callGraphic from '@/assets/images/call_tracking_mobile_call_graphic.png';

import BaseButton from '@/components/ui/BaseButton.vue';
import BaseDialog from '@/components/ui/BaseDialog.vue';
import ProgressBar from '@/components/ui/ProgressBar.vue';

const sellingPoints = [
	{
		icon: 'earth-americas',
		title: `Call Tracking Everywhere`,
		text: `Measure phone call conversions from your search, digital, and offline marketing campaigns – including Google Adwords, Yahoo and Bing SEM/PPC programs.`,
	},
	{
		icon: 'shuffle',
		title: `Dynamic Number Insertion`,
		text: `Your office number is automatically replaced with a trackable phone number on your secure agent domain sites and both the Mirus or State Farm Agent Quote Landing Pages`,
	},
	{
		icon: 'dollar-sign',
		title: `Identify Valuable Callers`,
		text: `Gain insight into the marketing efforts driving your inbound calls - whether they come from email, search, display, or any other marketing channel.`,
	},
];

const calltrackingStore = useCalltrackingStore();

const processing = ref(false);
const loadingBillingData = ref(false);
const billingData = ref(null);
const nextBillingDay = ref(null);

function cancel() {
	processing.value = false;
}

async function getBilling() {
	billingData.value = null;
	loadingBillingData.value = true;

	try {
		const { data } = await useApi('billing/calc/?amount=26', {
			agent: true,
			message: 'There was an issue getting your billing info.',
		}).json();

		billingData.value = data.value;
		nextBillingDay.value = format(new Date(data.value.next_billing_day), 'MMM d, yyyy');
	} finally {
		loadingBillingData.value = false;
	}
}

async function activate() {
	processing.value = true;

	try {
		await calltrackingStore.createAccount();
	} catch (error) {
		console.error(error);
	} finally {
		processing.value = false;
	}
}
</script>
